export default class AdditionalFee {

  static ID_DEALER_NOTARY = 1;
  static ID_MAVSIGN_NOTARY = 2;
  static ID_VERIFY_ID = 3;
  static ID_RUSH = 4;
  static ID_PRINTING = 5;
  static ID_ADDITIONAL_VEHICLE = 6;
  static ID_TRAVEL = 7;
  static ID_TRIP = 8;
  static ID_CANCELATION = 9;
  static ID_SCAN_FAX = 10;
  static ID_DOCUMENT_RETURN = 11;
  static ID_PARKING_TOLL_REIMBURSMENT = 12;
  static ID_ADDITIONAL_TIME_SURCHARGE = 13;
  static ID_FUEL_SURCHARGE = 14;
  static ID_ADDITIONAL_SERVICES = 15;
  static ID_EXPRESS_NOTARY = 16;
  static ID_SUPER_EXPRESS_NOTARY = 17;
  static ID_TRANSACTION_FEE = 18;
  static ID_PREVIEW_DOCUMENTS = 20;
  static ID_INSTORE_VERIFY_ID = 21;
  static ID_INK_SIGN = 22;
  static ID_E_SIGN = 23;
  static ID_EXTRA_PRINTING = 25;
  static ID_R1 = 26;
  static ID_GENERATE_OUTGOING_SHIPPING_LABEL = 27;
  static ID_GENERATE_RETURN_SHIPPING_LABEL = 28;
  static ID_GENERATE_LABEL_PROCESSING = 29;
  static ID_GENERATE_LABEL_PICKUP = 30;

  static FEE_PRICE_NOT_SET = 'Not set';

  static getAdditionalFeeMap() {
    return {
      [this.ID_DEALER_NOTARY]: "Dealer's Notary Fee",
      [this.ID_MAVSIGN_NOTARY]: 'Mavsign Notary Fee',
      [this.ID_VERIFY_ID]: 'VerifyID Fee',
      [this.ID_RUSH]: 'Rush Fee',
      [this.ID_PRINTING]: 'Printing Fee',
      [this.ID_ADDITIONAL_VEHICLE]: 'Additional Vehicle',
      [this.ID_TRAVEL]: 'Travel Fee',
      [this.ID_TRIP]: 'Trip Fee',
      [this.ID_CANCELATION]: 'Cancellation Fee',
      [this.ID_SCAN_FAX]: 'Scan/Fax Fee',
      [this.ID_DOCUMENT_RETURN]: 'Document Return Fee',
      [this.ID_PARKING_TOLL_REIMBURSMENT]: 'Parking/Toll Reimbursement',
      [this.ID_ADDITIONAL_TIME_SURCHARGE]: 'Additional Time Surcharge',
      [this.ID_FUEL_SURCHARGE]: 'Fuel Surcharge Fee',
      [this.ID_ADDITIONAL_SERVICES]: 'Additional Services',
      [this.ID_EXPRESS_NOTARY]: 'Express Notary Fee',
      [this.ID_SUPER_EXPRESS_NOTARY]: 'Super Express Notary Fee',
      [this.ID_TRANSACTION_FEE]: 'Transaction Fee',
      [this.ID_PREVIEW_DOCUMENTS]: 'Preview Documents Fee',
      [this.ID_INSTORE_VERIFY_ID]: 'Instore VerifyID Fee',
      [this.ID_INK_SIGN]: 'Wet Signing Fee',
      [this.ID_E_SIGN]: 'Electronic Signing Fee',
      [this.ID_EXTRA_PRINTING]: 'Extra Printing Fee',
      [this.ID_R1]: 'Route One Fee',
      [this.ID_GENERATE_OUTGOING_SHIPPING_LABEL]: 'Outgoing Generated Label Fee',
      [this.ID_GENERATE_RETURN_SHIPPING_LABEL]: 'Return Generated Label Fee',
      [this.ID_GENERATE_LABEL_PROCESSING]: 'Generate Label Processing Fee',
      [this.ID_GENERATE_LABEL_PICKUP]: 'Mavsign Scheduled Pickup Fee',
    };
  }

  static getAdditionalFeeName(id) {
    return this.getAdditionalFeeMap()[id];
  }

  // Fees that appear in the Additional Fees section
  static getNotarySurchargeFees() {
    return [
      this.ID_PRINTING,
      this.ID_ADDITIONAL_VEHICLE,
      this.ID_TRAVEL,
      this.ID_TRIP,
      this.ID_CANCELATION,
      this.ID_SCAN_FAX,
      this.ID_DOCUMENT_RETURN,
      this.ID_PARKING_TOLL_REIMBURSMENT,
      this.ID_ADDITIONAL_TIME_SURCHARGE,
      this.ID_FUEL_SURCHARGE,
      this.ID_PREVIEW_DOCUMENTS,
      this.ID_EXTRA_PRINTING,
      this.ID_GENERATE_LABEL_PICKUP
    ]
  }
}