import React from "react";
import { useSelector } from "react-redux";
import Order from "model/order";
import specialInstructionsIcon from '../../../../../../assets/images/special-instructions-icon.svg'
import { newLineTextFormat } from "../../../../../../helpers/utilHelper";
import DealerGroup from "model/dealerGroup";
import PrintAllDocs from "../../../../../../components/Shared/PrintAllDocs";
import DocumentExpectedSection from "../../DocumentExpected"

const StepBeforeSigning = () => {

  /********** STATE **********/

  const { order } = useSelector(state => state.Order.Single);
  const { docs } = useSelector(state => state.Order.InkSignDocs);
  const { docs: inkSignDocs } = useSelector(state => state.Order.InkSignDocs);

  const isDeliveryOptionUploaded = order?.docDeliveryOption === Order.DOC_DELIVERY_OPTION_UPLOAD;
  const isDeliveryOptionShipping = order?.docDeliveryOption === Order.DOC_DELIVERY_OPTION_SHIPPING;
  const downloadBtnClassName = `btn document-download pb-0 ${isDeliveryOptionUploaded || isDeliveryOptionShipping ? '' : 'no-event'}`;
  const canBeDownload = () => !isDeliveryOptionShipping || isDeliveryOptionUploaded;

  // Split the text into separate lines
  let lines = order?.dealerGroupSpecialInstructions?.split('\n');

  const isGroupSpecialInstructionsAppliesTo = () => order?.dealerGroupSpecialInstructionsAppliesTo?.some(item => item == DealerGroup.SPECIAL_INTSTRUCTIONS_APPLY_TO_ADMIN);
  const showGroupSpecialInstructions = () => order?.dealerGroupSpecialInstructions && isGroupSpecialInstructionsAppliesTo();

  return (
    <React.Fragment>
      {order.dealerInstructions && (
        <>
          <h6>{"Dealer's instructions:"}</h6>
          <ul>
            <li>{order.dealerInstructions}</li>
          </ul>

          <div className="divider-faded divider-sm" />
        </>
      )}

      <h6>Documents to bring</h6>
      <ul>
        {docs?.map(doc => (
          <li key={doc.id}>{doc.name}</li>
        ))}
        {order.itemsToCollect?.map(doc => <li key={doc}>{Order.getItemToCollectName(doc)}</li>)}
        {!!order.returnShippingLabel && <li>Return Label</li>}
      </ul>

      {!!order.additionalItemsToCollect && <>
        <div className="divider-faded divider-sm" />
        <h6>Also the dealership asked you to collect:</h6>
        <p>{order.additionalItemsToCollect}</p>
      </>}

      <div className="divider-faded divider-sm" />

      <h6>Instructions</h6>
      <ul>
        <li>
          {"Don't forget to bring a printed AOI form with you."}
        </li>
        <li>
          {"In case the customer changes any info about the appointment right before the meeting, let us know at *99915834785*."}
        </li>
        <li>
          {"In case the customer refuses to provide the thumbprint, complete the signing without it and communicate to your scheduler about their refusal."}
        </li>
      </ul>

      <div className="divider-faded divider-sm" />
      <DocumentExpectedSection order={order}
        inkSignDocs={inkSignDocs}
        isDeliveryOptionUploaded={isDeliveryOptionUploaded}
        downloadBtnClassName={downloadBtnClassName}
        canBeDownload={canBeDownload}
      />
      <PrintAllDocs id={order.id} />
      {showGroupSpecialInstructions() && <div>
        <div className="divider-faded divider-sm" />
        <div className='d-flex align-items-center mb-3'>
          <img src={specialInstructionsIcon} alt='special-instructions' />
          <h6 className='mb-0 ms-2'>Group Special Instructions</h6>
        </div>
        <div className="ps-2">
          {newLineTextFormat(lines).map((line, index) => <p className='mb-1' key={index}>{line}</p>)}
        </div>
      </div>}
    </React.Fragment>
  )
};

export default StepBeforeSigning;