import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { useFormik } from "formik";
import { Row, Col, Button, Label } from "reactstrap";
import { nullsToEmptyStrings } from "helpers/utilHelper";
import Order from "model/order";
import AdditionalFee from "model/additionalFee";
import { formats, formatTimestamp, timestamp } from "helpers/dateHelper";

const ShipmentSummary = props => {

  const { tabId, nextHandler, prevHandler, order, allValues, isSaveInProgress, deliveryRating, availableFees } = props;

  // Estimation Fee received by Fedex
  const [estimatedPrice, setEstimatedPrice] = useState();
  // Processing Fee from Mav
  const [feeProcessingPrice, setFeeProcessingPrice] = useState(0);
  // Arrival expected date
  const [arrivalExpectedDate, setArrivalExpectedDate] = useState("")

  /********** FORM CONFIG **********/

  const formInitialValues = {
    ...nullsToEmptyStrings(allValues),
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    onSubmit: values => {
      const destructuredValues = { ...values[0], ...values[1] }
      nextHandler(tabId, destructuredValues);
      formik.setSubmitting(false);
    },
  });

  /********** EFFECTS **********/

  // Set the estimated fees
  useEffect(() => {
    if (!!availableFees?.length && !!deliveryRating) {
      getEstimatedPrice(deliveryRating[0]);
      setFeeProcessingPrice(availableFees.find((fee) => fee.id === AdditionalFee.ID_GENERATE_LABEL_PROCESSING).price);
    }
  }, [availableFees, deliveryRating])

  // Extracting price from provider response
  const getEstimatedPrice = (serviceRate) => {
    if (!!serviceRate) {
      const accountRate = serviceRate.ratedShipmentDetails.find((rate) => rate.rateType === "MAV");
      if (order.docDeliveryOption === Order.DOC_DELIVERY_OPTION_SHIPPING) {
        setEstimatedPrice(accountRate.outgoingFee + accountRate.returnFee); // Adding both for shipping (return and outgoing labels)
      } else {
        setEstimatedPrice(accountRate.returnFee);
      }
      // Setting the delivery date
      const deliveryDate = timestamp(new Date(serviceRate.operationalDetail.deliveryDate));
      const formattedDate = formatTimestamp(deliveryDate, formats.TRACKING_DATE);
      setArrivalExpectedDate(formattedDate)
    }
  }

  return <React.Fragment>
    <div className="card-section px-0">
      <div className="d-flex">
        {
          order.docDeliveryOption === Order.DOC_DELIVERY_OPTION_SHIPPING
            ?
            <>
              <div style={{ width: `${Math.floor(100 / 3)}%` }} className="m-1">
                <div className="card-title mb-2">Shipper Information</div>
                <p className="mb-1">Dealership name: <strong>{allValues[0].dealerStoreName}</strong></p>
                <p className="mb-1">Person submitting order: <strong>{allValues[0].dealerName}</strong></p>
                <p className="mb-1">Sender Address: <strong>{allValues[0].dealerAddress}</strong></p>
                <p className="mb-1">City: <strong>{allValues[0].dealerCity}</strong></p>
                <p className="mb-1">State: <strong>{allValues[0].dealerState}</strong></p>
                <p className="mb-1">ZIP: <strong>{allValues[0].dealerZip}</strong></p>
              </div>
              <div style={{ width: `${Math.floor(100 / 3)}%` }} className="m-1">
                <div className="card-title mb-2">Ship To</div>
                <p className="mb-1">Notary name: <strong>{allValues[0].notaryFullName}</strong></p>
                <p className="mb-1">Notary Address: <strong>{allValues[0].notaryAddress}</strong></p>
                <p className="mb-1">City: <strong>{allValues[0].notaryCity}</strong></p>
                <p className="mb-1">State: <strong>{allValues[0].notaryState}</strong></p>
                <p className="mb-1">ZIP: <strong>{allValues[0].notaryZip}</strong></p>
              </div>
            </>
            :
            <>
              <div style={{ width: `${Math.floor(100 / 3)}%` }} className="m-1">
                <div className="card-title mb-2">Shipper Information</div>
                <p className="mb-1">Notary name: <strong>{allValues[0].notaryFullName}</strong></p>
                <p className="mb-1">Notary Address: <strong>{allValues[0].notaryAddress}</strong></p>
                <p className="mb-1">City: <strong>{allValues[0].notaryCity}</strong></p>
                <p className="mb-1">State: <strong>{allValues[0].notaryState}</strong></p>
                <p className="mb-1">ZIP: <strong>{allValues[0].notaryZip}</strong></p>
              </div>
              <div style={{ width: `${Math.floor(100 / 3)}%` }} className="m-1">
                <div className="card-title mb-2">Ship To</div>
                <p className="mb-1">Dealership name: <strong>{allValues[0].dealerStoreName}</strong></p>
                <p className="mb-1">Person submitting order: <strong>{allValues[0].dealerName}</strong></p>
                <p className="mb-1">Sender Address: <strong>{allValues[0].dealerAddress}</strong></p>
                <p className="mb-1">City: <strong>{allValues[0].dealerCity}</strong></p>
                <p className="mb-1">State: <strong>{allValues[0].dealerState}</strong></p>
                <p className="mb-1">ZIP: <strong>{allValues[0].dealerZip}</strong></p>
              </div>
            </>
        }
        <div style={{ width: `${Math.floor(100 / 3)}%` }} className="m-1">
          <div className="card-title mb-2">Shipping Information</div>
          {
            order.shippingCompany === Order.SHIPPING_COMPANY_FEDEX &&
            <>
              <p className="mb-1">Delivery method: <strong>{allValues[1].deliveryMethod?.serviceName}</strong></p>
              <p className="mb-1">Packaging Type: <strong>{Order.getFedexPackagingTypes()[allValues[1].packagingType?.packageType]}</strong></p>
            </>
          }
          {
            order.docDeliveryOption === Order.DOC_DELIVERY_OPTION_SHIPPING &&
            <p className="mb-1">Ship Date: <strong>{formatTimestamp(allValues[1].shipDate, formats.SHIP_DATE_FORMAT)}</strong></p>
          }
        </div>
      </div>
      <div className="pb-4">
        {
          !!availableFees && !!estimatedPrice &&
          <div className="mt-5 mb-2">
            <div className="pb-3 d-flex flex-direction-row align-items-center">
              <div className="font-weight-600">Arrival Expected Date -</div>
              <div className="font-weight-600 ms-1">
                {arrivalExpectedDate}
              </div>
            </div>
            <div className="pb-3 d-flex flex-direction-row align-items-center">
              <div>Estimated Delivery Price</div>
              <div className="ms-2">${estimatedPrice.toFixed(2)}</div>
            </div>
            <div className="pb-3 d-flex flex-direction-row align-items-center">
              <div>Processing Fee</div>
              <div className="ms-2">${feeProcessingPrice}</div>
            </div>
            <div className="pb-3 d-flex flex-direction-row align-items-center">
              <div className="font-weight-600">Estimated Total Price</div>
              <div className="font-weight-600 ms-2">
                ${(+estimatedPrice + +feeProcessingPrice).toFixed(2)}
              </div>
            </div>
            <Row className="mb-2">
              <Col>
                <div className="mt-1 font-size-11"><i className="mdi mdi-information-outline me-1 font-size-12"></i>Please note that the price displayed is an approximation and may vary once the label is generated.</div>
              </Col>
            </Row>
          </div>
        }
        <Row>
          <Col>
            <div className="text-end">
              <Button type="button" color="secondary" className="ms-2 mb-2" onClick={prevHandler}>
                <i className="mdi mdi-chevron-left me-1" />
                Previous
              </Button>
              <Button type="button" color="primary" className="ms-2 mb-2" onClick={formik.handleSubmit} disabled={isSaveInProgress}>
                {isSaveInProgress && <i className="mdi mdi-spin mdi-loading me-1" />}
                {!isSaveInProgress && <i className="mdi mdi-check me-1" />}
                Generate Label
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  </React.Fragment>
}

ShipmentSummary.propTypes = {
  defaultValues: PropTypes.object,
  availableFees: PropTypes.any,
  tabId: PropTypes.number,
  nextHandler: PropTypes.func,
  prevHandler: PropTypes.func,
  order: PropTypes.object,
  setDeliveryRating: PropTypes.func,
  deliveryRating: PropTypes.array,
  allValues: PropTypes.object,
  isSaveInProgress: PropTypes.bool
};

export default ShipmentSummary;