import React, { useState } from "react"
import { Container, Card, UncontrolledAccordion, AccordionBody, AccordionItem, AccordionHeader } from"reactstrap";
import { routeSetup, howToSubmit } from "./../constants";
import MetaTitle from "components/Shared/MetaTitle";
import Banner from "../Partial/Section/Banner";
import AccordionRow from "../Partial/Section/AccordionRow";
import Video from "../Partial/Section/Video";


const RouteOne = () => {
  // Set the first accordeon open by default
 const [open, setOpen] = useState(true);

  const toggle = id => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  return <React.Fragment>
    <div className="page-content">
      <MetaTitle>RouteOne</MetaTitle>
      <Container className="marketing-page" fluid>
        <Card className="height-100">
          <Banner path="routeOne" />

          <div className="marketing-page-accordion-container mt-5">
            <UncontrolledAccordion className="mb-3" open={open} toggle={toggle} defaultOpen={['route-setup']} stayOpen >
              <AccordionItem key='route-setup'>
                <AccordionHeader targetId='route-setup'>
                  <div>
                    <h6 className="accordion-title m-0">How to Setup RouteOne:</h6>
                    <p className="accordion-description mt-3">Getting started is simple! Follow these easy steps to sign up and start sending orders from RouteOne to Mavsign.</p>
                  </div>
                </AccordionHeader>
                <AccordionBody accordionId='route-setup'>
                  <Video url={'https://www.youtube.com/embed/Z8CxCMV6uDs?si=7xKbtuCacxpLWEXu'} />
                  {routeSetup.map((routeSetupItem, index) => <AccordionRow key={index} title={routeSetupItem.title} description={routeSetupItem.description} />)}
                </AccordionBody>
              </AccordionItem>
            </UncontrolledAccordion>

            <UncontrolledAccordion open={open} toggle={toggle} >
              <AccordionItem key='how-to-submit'>
                <AccordionHeader targetId='how-to-submit'>
                  <h6 className="accordion-title m-0">How to Submit your First RouteOne Order:</h6>
                </AccordionHeader>
                <AccordionBody accordionId='how-to-submit'>
                  <Video url={'https://www.youtube.com/embed/EMpk1rJ_i9Y?si=PnCOcIXD17k7pfvI'} />
                  {howToSubmit.map((routeSetupItem, index) => <AccordionRow key={index} title={routeSetupItem.title} description={routeSetupItem.description} />)}
                </AccordionBody>
              </AccordionItem>
            </UncontrolledAccordion>
          </div>
        </Card>
      </Container>
    </div>
  </React.Fragment>
}

export default RouteOne;
