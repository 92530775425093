export const routeSetup = [
  {
    title: 'Step 1: Log In to Your RouteOne Dashboard',
    description: `Begin by logging into your RouteOne dashboard. Once you're in, look at the top-left corner of the screen. You'll see your store name, and right next to it is your RouteOne Store ID. Copy that ID because you'll need it in the next step.`
  },
  {
    title: 'Step 2: Update Your Mavsign Profile',
    description: `Now, head over to your Mavsign Store profile. Paste the RouteOne Store ID into the designated field, ensuring everything is entered correctly.`
  },
  {
    title: 'Step 3: Save Your Changes',
    description: `Once you've entered the ID, click the "Save" button to secure your updates.`
  },
  {
    title: 'Step 4: Check Your Activation Status',
    description: `After saving, your account will display a "Pending Activation" status. This is part of the process. Just wait for 1 to 2 hours and then check back to see if the status changes.`
  },
  {
    title: 'Step 5: Finalize Your Sign up',
    description: `When the status updates to "Connection Active," log back into your RouteOne dashboard. From there, complete the signup process by agreeing to the E.D.P.A. Terms of Service. This step ensures everything is set up and ready to go.`
  },
  {
    title: 'Step 6: Start Sending Orders',
    description: `Congratulations! Your setup is now complete, and you are ready to send your first order from RouteOne to Mavsign. If you would like to dive deeper or learn more about this process, additional resources are available to guide you. Check out the next tutorial about submitting orders from RouteOne to Mavsign. Thank you for choosing Mavsign! `
  },
];

export const howToSubmit = [
  {
    title: 'Step 1: Log In and Find Your Deal',
    description: `Start by logging into your RouteOne dashboard.  Once inside, navigate to the Dealer Manager page and locate the deal you wish to submit.`,
  },
  {
    title: 'Step 2: Export the Deal to Mavsign',
    description: `Click on the decision application and choose Export to DSP.  On the export screen, select Mavsign from the DSP options, choose the decision you'd like to export, and then click Export.`,
  },
  {
    title: 'Step 3: Access the Order in Mavsign',
    description: `Log in to your Mavsign account and go to the Orders page. You'll see your exported order in Draft status, ready to be processed.`,
  },
  {
    title: 'Step 4: Customize and Finalize the Order',
    description: `Open the draft to begin editing. From the order creation page:Select the services needed for the deal.
Verify the pre-filled customer and vehicle information for accuracy. Pay close attention to the address, if it's not accurate this can cause errors in mapping the closest notaries in the proximity of your customer.
Choose the appropriate delivery option for the order.`,
  },
  {
    title: 'Step 5: Upload Documents and Review Details',
    description: `On the Dealer Checklist page, upload any required documents for the deal.  Next, review the order summary to confirm all the information is correct.`,
  },
  {
    title: 'Step 6: Submit the Order',
    description: `Once everything is verified, click Submit to complete the process. And that's it!
      You've successfully submitted your first order from RouteOne to Mavsign. It's fast, efficient, and designed to save you time. If you have any questions or need more information, we've got you covered with additional resources. Thank you for choosing Mavsign! `,
  }
]

export const fedexSetup = [
  {
    title: `Step 1:`,
    description: `<p>To get started, begin by creating your order as usual. Remember, this option is available only for Notary-Assisted orders. If your order isn't notary-assisted, this feature will not be available.</p>`
  },
  {
    title: `Step 2:`,
    description: `<p>First, fill in your customer's information. This is standard, just like any other order. Then, fill in the vehicle information.</p>`
  },
  {
    title: `Step 3:`,
    description: `<p>Now, it's time to select the delivery method for the documents that need to be signed. If you want to ship your documents to the notary, FedEx labels will be generated for both the outgoing and return packages.</p><p>If you plan to upload the documents to be signed, the system will generate only the return label.</p>`
  },
  {
    title: `Step 4:`,
    description: `<p>After selecting your delivery method, go to the Shipping Labels field. Here, select the option to Generate labels with Mavsign. Some key steps are important to pay attention to here.</p><p>First, Labels will be generated by our team once the notary is assigned. A FedEx fee will apply. Both the Outgoing and Return labels will be generated by Mavsign.</p><p>Secondly, make sure your dealership's address is correct so that the documents are returned back to your store.</p><p>And lastly, make sure you select the correct date of when you intend to send the documents to the Notary.</p><p>Before moving on to the next step, let's make sure you've selected the right method for your document pickup. To ensure a seamless document submission process please let us know your preferred method for sending your documents to FedEx.</p><p>Let’s take a look at your options. If you already have a daily FedEx pickup pre-arranged for your location, go ahead and select Scheduled Pickup at Your Location. This means FedEx will come directly to you to collect the documents. Easy, right?</p><p>Alternatively, if you prefer, you can drop off your documents at any nearby FedEx location. Simply select Drop-off at a FedEx Location.</p><p>And if you don’t want to head to a FedEx location and don’t have a pre-arranged daily pickup at your store, no worries! With Mavsign Scheduled Pickup, we’ve got you covered. Our team will contact you after the labels are generated to confirm the preferred date and time of the FedEx pick up, then Mavsign will schedule the pickup with FedEx on your behalf. An additional fee will be applied for this option.</p><p>Once you’ve selected the best pickup method for you, you’re all set to move on to the next step.</p><p>While our team is working to identify the best notary to assist with this signing, the Tracking and Labels section will not populate the labels.</p>`
  },
  {
    title: `Step 5:`,
    description: `<p>Once a notary is assigned to your order, you can find the labels in the Tracking and Labels section.The generated labels will be emailed to you as well.</p>
    <p>If documents will be shipped to the notary simply print the labels, place the return label within the documents and adhere the outgoing label to the FedEx envelope.</p>
    <p>For Upload orders your job is done here! The notary will print all documents and the return label.</p>
    <p>And that's all! You've successfully set up FedEx shipping labels using Mavsign. Thanks for watching, and if you have any questions, don't hesitate to reach out to our support team!</p>`
  }
];
