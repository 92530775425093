import { UnprocessableEntityException } from "./errorHelper";
import { showError } from "./utilHelper";

export const checkGenerateLabelError = (ex) => {
  let errMessage = "An error occurred. Please check your input and try again.";
  if (ex instanceof UnprocessableEntityException) {
    // FEDEX ERROR MESSAGES
    switch (ex.message) {
      case "COUNTRY.POSTALCODEORZIP.INVALID":
        errMessage = "Invalid postal code/ZIP for the country selected. Please correct and try again.";
        break;
      case "CURRENCY.TYPE.INVALID":
        errMessage = "The currency type you selected is invalid. Please select another currency type.";
        break;
      case "CURRENCYTYPE.CADORUSD.REQUIRED":
        errMessage = "CAD or USD are the only currency types available. Please select CAD or USD as your currency type.";
        break;
      case "CUSTOMVALUE.LIMITEXCEEDS.ERROR":
        errMessage = "The total declared value for customs entered exceeds the limit for FedEx® international services.For additional information, including other services that may be available, please contact FedEx Customer Service.";
        break;
      case "DIMENSIONS.EXCEEDS.LIMITS":
        errMessage = "Dimensions exceeds the maximum dimensions for this service. Please refer to the FedEx Service Guide.";
        break;
      case "POSTALCODE.ZIPCODE.REQUIRED":
        errMessage = "Postal code/ZIP is required";
        break;
      case "CURRENCY.TYPE.MISMATCH":
        errMessage = "Declared value currency type must be equal to customs value currency type.";
        break;
      case "ACCOUNT.NUMBER.INVALID":
        errMessage = "Invalid account number.";
        break;
      case "ACCOUNTNUMBER.ENROLLED.INVALID":
        errMessage = "The shipping account number is not enrolled in FedEx Priority Alert(TM) service.";
        break;
      case "ACCOUNTNUMBER.ONERATE.NOTAVBL":
        errMessage = "FedEx One Rate® is not available for this account. Please use FedEx Standard Rate.";
        break;
      case "CARRIAGE.VALUE.EXCEEDSMAXIMUM":
        errMessage = "The declared value for carriage entered exceeds the maximum. Please verify and reenter";
        break;
      case "CARRIAGEVALUE.CUSTOMVALUE.VALIDATION":
        errMessage = "Rates and transit times are not available for the account number entered. For immediate assistance, please contact your local FedEx Customer Service.";
        break;
      case "CITY.REQUIRED":
        errMessage = "A valid city is required";
        break;
      case "COUNTRY.POSTALCODE.INVALID":
        errMessage = "Origin postal code is required or invalid.";
        break;
      case "SHIPPER.POSTALSTATE.MISMATCH":
        errMessage = "Shipper Postal-State Mismatch. Please correct and try again.";
        break;
      case "CURRENCY.MISSMATCH.ERROR":
        errMessage = "Package insured value currency does not match the customs value currency.";
        break;
      case "CURRENCY.TYPE.SELECT":
        errMessage = "Please enter Currency";
        break;
      case "DELIVERY.DAY.ERROR":
        errMessage = "Please choose either Saturday Pickup or Saturday Delivery.";
        break;
      case "DIMENSION.EXCEEDS.MAXMUM":
        errMessage = "The dimensions entered exceed the maximum dimensions for this service. Please refer to the FedEx Service Guide.";
        break;
      case "DIMENSION.TYPE.ERROR":
        errMessage = "The dimensions you have entered exceed the limit for the origin and/or destination selected; please verify and reenter.";
        break;
      case "DIMENSIONS.EXCEEDS.LIMIT":
        errMessage = "The dimensions you have entered exceed the height limit allowed; please verify and reenter.";
        break;
      case "ENTERED.ZIPCODE.NOTFOUND":
        errMessage = "The state or province and ZIP or postal code entered was not found, or the state or province and ZIP or postal code are not served by FedEx in this country.";
        break;
      case "SERVICE.PACKAGECOMBINATION.INVALID":
        errMessage = "Invalid service and packaging combination.";
        break;
      case "FEDEXHOMEDELIVERY.SERVICE.QUALIFICATIONERROR":
        errMessage = "Based on the information entered this shipment qualifies for FedEx Home Delivery®. Please resubmit your request as FedEx Home Delivery to continue with the shipment.";
        break;
      case "FEDEXSERVICE.NOT.AVAILABLE":
        errMessage = "FedEx does not provide services to the destination country or from the origin country at this time. Contact FedEx Customer Service.";
        break;
      case "FREIGHTGUARANTEE.TIMEVALUE.REQUIRED":
        errMessage = "A valid Freight Guarantee time value is required.";
        break;
      case "FREIGHTGUARANTEEE.TIME.REQUIRED":
        errMessage = "The packaging type selectet cannot exceed the selected weight. Select another packaging type.";
        break;
      case "DECLAREDVALUE.NOT.ALLOWED.FOR.SMARTPOST":
        errMessage = "Declared Value is not allowed for SmartPost.";
        break;
      case "LOCATION.PICKUP.NOTAVAILABLE":
        errMessage = "Your location does not allow pickup. Select Drop-off to drop your package off at a FedEx location or contact FedEx Customer Service.";
        break;
      case "MAXIMUMWEIGHT.TYPE.ERROR":
        errMessage = "Maximum weight allowed for this application is 68kg/150lbs. Please re-enter weight or contact FedEx Customer Service.";
        break;
      case "ORIGIN.COUNTRY.REQUIRED":
        errMessage = "A valid origin country is required";
        break;
      case "ORIGIN.PICKUP.ERROR":
        errMessage = "The origin selected does not allow pickup for FedEx Express Freight services. You may contact your local FedEx Customer Service (U.S. and Canada, please dial 1.800.GoFedEx 1.800.463.3339) for other shipment collection options.";
        break;
      case "ORIGIN.PICKUP.NOTALLOWED":
        errMessage = "The declared value for carriage entered exceeds the maximum. Please verify and re-enter.";
        break;
      case "PACKAGE.INVALID.TYPE":
        errMessage = "Section II Lithium Batteries/Cells are not allowed with a document shipment.";
        break;
      case "PACKAGE.WEIGHT.INVALID":
        errMessage = "Package weight is missing or invalid.";
        break;
      case "PACKAGEDIMENSION.TYPE.ERROR":
        errMessage = "Package dimensions must be at least 1 cm/inch for length, width and height. If you''re not sure of the dimensions, you can leave these fields blank.";
        break;
      case "RATE.SPECIALSERVICETYPE.NOTALLOWED":
        errMessage = "Special Service is not allowed.";
        break;
      case "PICKUPTYPE.NOT.AVAILABLE":
        errMessage = "Pickup is not available. Please change the service type, the ship date or select dropoff to complete the shipment.";
        break;
      case "RATING.CARRIAGE.EXCEEDSLIMITS":
        errMessage = "Declared value for carriage exceeds limit allowed based on the information entered.";
        break;
      case "RATING.CARRIAGLIMITS.EXCEED":
        errMessage = "Dimensions exceed the maximum dimensions for this service. Please refer to the FedEx Service Guide.";
        break;
      case "RATING.DIMENSIONSEXCEEDS.LIMITS":
        errMessage = "Customs Value exceeds limit of the amount.";
        break;
      case "RATING.DIMENSIONSTYPE.EXCEEDSLIMITS":
        errMessage = "Dangerous Goods shipping has not been enabled for your account. Please call your local FedEx customer service for assistance.";
        break;
      case "RATING.ZIPCODE.INVALID":
        errMessage = "Information about rates and delivery times is not available. Try again later or contact FedEx Customer Service";
        break;
      case "RATE.PACKAGES.WEIGHT.UNIT.MISMATCH":
        errMessage = "Weight unit of measure must be the same for all packages.";
        break;
      case "RESIDENTIAL.DELIVERY.NOTAVAILABLE":
        errMessage = "Residential delivery is not available for the selected service.";
        break;
      case "SERVICE.LOCATION.UNAVAILABLE":
        errMessage = "FedEx service is not currently available to this origin / destination combination. Enter new information or contact FedEx Customer Service (U.S. and Canada, please dial 1.800.GoFedEx 1.800.463.3339).";
        break;
      case "SERVICETYPE.FORPOSTALCODE.NOTALLOWED":
        errMessage = "The declared value for carriage entered exceeds the maximum. Please verify and re-enter.";
        break;
      case "SHIPMENT.DGDIVIOLATIONS.NOTALLOWED":
        errMessage = "Your shipment is not allowed because of dangerous goods or dry ice violations that limit your shipping options. If you have any questions, please call your local FedEx customer service.";
        break;
      case "SHIPMENT.DGVIOLATIONS.NOTALLOWED":
        errMessage = "Your shipment is not allowed because of dangerous goods violations that limit your shipping options. If you have any questions, please call 1.800.463.339 and press '81' for the FedEx Dangerous Goods/Hazardous Materials hotline.";
        break;
      case "WEIGHT.BELOWMINIMUMLIMIT.ERROR":
        errMessage = "The weight entered is below the standard minimum for FedEx {package} Express services. Please enter another weight.";
        break;
      case "SHIPMENTPROCESSING.ACCOUNTVIOLATIONS.ERROR":
        errMessage = "There are no services returned due to dangerous goods, hazardous materials, or dry ice violations on your account.";
        break;
      case "STATE.TYPE.INVALID":
        errMessage = "A valid State/Province is required";
        break;
      case "STATE.TYPE.REQUIRED":
        errMessage = "State/Province is required";
        break;
      case "SUBTYPE.SELECT.FORBATTERIES":
        errMessage = "Section II Lithium Batteries/Cells and one of the sub-types must be selected.";
        break;
      case "WEIGHT.TYPE.INVALID":
        errMessage = "Please enter a valid weight.";
        break;
      case "RATE.QUOTENO.NOTFOUND":
        errMessage = "Rate quote number could not be found";
        break;
      case "ACCOUNTNBR.MISSING.ORINVALID":
        errMessage = "The condition type ACCOUNT_NUMBER value is missing or invalid";
        break;
      case "SPECIAL.SERVICE.CONFLICT":
        errMessage = "Special service conflict.Hold At Location is not valid with Residential Delivery.";
        break;
      case "DRYICEWEIGHT.REQUIRED.INKGS":
        errMessage = "Package {0} - Dry Ice weight (in kilograms) is required with Dry Ice special service.";
        break;
      case "SERVICETYPE.PICKUPMETHOD.NOT.ALLOWED":
        errMessage = "{0} is not allowed with the pickup method.";
        break;
      case "FXSPRETURN.RATES.ERROR":
        errMessage = "Rates for SmartPost return shipments are not currently available.";
        break;
      case "RATE.SPECIALSERVICE.NOTALLOWED.ORIGIN":
        errMessage = "{0} is not allowed for the origin.";
        break;
      case "PACKAGINGTYPE.MISSING.OR.INVALID":
        errMessage = "Packaging type missing or invalid.";
        break;
      case "RATE.SPECIALSERVICE.NOT.ALLOWED":
        errMessage = "Package – Special Service is not allowed.";
        break;
      case "SMARTPOST.HUBID.INVALID":
        errMessage = "SmartPost hub id is invalid.";
        break;
      case "SPECIALSERVICE.NOT.ALLOWED.SHIPMENTORPACKAGE":
        errMessage = "Special Service {0} not allowed at {1} level.";
        break;
      case "SPECIALSERVICE.NOT.SUPPORTED.REQUESTED.SHIPDAY":
        errMessage = "{0} is not supported for the requested ship day of the week. Please update and try again.";
        break;
      case "CODDETAIL.EMPTY.ORNULL":
        errMessage = "Package {0} - CodDetail object cannot be empty.";
        break;
      case "DESTINATION.POSTALCODE.MISSING.ORINVALID":
        errMessage = "Destination postal code missing or invalid.";
        break;
      case "TOTALCUSTOMSVALUE.CURRENCYTYPE.INVALID":
        errMessage = "Invalid currency type for total customs value.";
        break;
      case "RATE.PACKAGES.DIMENSION.UNIT.MISMATCH":
        errMessage = "Dimension unit of measure must be the same for all packages.";
        break;
      case "COMMODITY.NUMBEROFPIECES.REQUIRED":
        errMessage = "Commodity - Number of pieces is required and cannot be a negative value or exceed 9,999.";
        break;
      case "PICKUPTYPE.REQUIRED":
        errMessage = "PickupType element is required in input request.";
        break;
      case "ORIGIN.COUNTRY.INVALID":
        errMessage = "Origin country code is invalid or missing. Please refer to documentation for valid format.";
        break;
      case "DESTINATION.COUNTRY.INVALID":
        errMessage = "Destination country code is invalid or missing. Please refer to documentation for valid format.";
        break;
      case "MAXIMUM.WEIGHT.EXCEEDED":
        errMessage = "The package weight in the request is not supported. Maximum package weight across all services is 2200 lbs or 998 kgs.";
        break;
      case "ACCOUNT.NUMBER.MISMATCH":
        errMessage = "When payment Type is SENDER, ShippingChargesPayment Payor AccountNumber should match the shipper account number.";
        break;
      case "SHIPDATESTAMP.FORMAT.INVALID":
        errMessage = "Please provide a valid shipDatestamp format YYYY-MM-DD";
        break;
      case "INTERNAL.SERVER.ERROR":
        errMessage = "Fedex encountered an unexpected error and are working to resolve the issue. We apologize for any inconvenience. Please check back at a later time.";
        break;
      case "RATING.DESTINATIONSTATEPOSTAL.MISMATCH":
        errMessage = "Destination state postal code mismatch.";
        break;
      case "ACCOUNT.NUMBERORKEY.INVALID":
        errMessage = "Invalid account information. Account number and/or key is invalid.";
        break;
      case "RATING.QUOTE.NOTAVAILABLE":
        errMessage = "Rates and transit times are not available for the account number entered. For immediate assistance, please contact your local FedEx Customer Service.";
        break;
      case "SHIPPER.COUNTRY.INVALID":
        errMessage = "Shipper country code is invalid or missing. Please update and try again.";
        break;
      case "FREIGHTSERVICEORIGIN.FREIGHT.NOTALLOWED":
        errMessage = "The origin is not served for Freight services.";
        break;
      case "FREIGHTSERVICEORIGIN.PICKUP.NOTALLOWED":
        errMessage = "The origin does not allow pickup for Freight services.";
        break;
      case "REGIONAL.MAILPICKUP.NOTALLOWED":
        errMessage = "Regional Mail Pickup is not allowed.";
        break;
      case "REGIONAL.MAILDELIVERY.NOTALLOWED":
        errMessage = "Regional Mail Delivery is not allowed.";
        break;
      case "FREIGHT.GUARANTEE.NOTALLOWED":
        errMessage = "Freight Guarantee is not allowed.";
        break;
      case "SHIPMENT.CUSTOMSVALUE.REQUIRED":
        errMessage = "Commodity - Customs value is required.Please update and try again.";
        break;
      case "ORIGINZIPCODE.SERVICE.ERROR":
        errMessage = "This origin postal code is not serviced.";
        break;
      case "SERVICETYPE.NOT.ALLOWED":
        errMessage = "Selected Service Type is not allowed between origin and destination. Please update and try again.";
        break;
      case "ACCOUNT.HAZARDOUSMATERIALS.NOTALLOWED":
        errMessage = "Hazardous material shipping is not enabled for your account.";
        break;
      case "DECLAREDVALUE.EXCEEDS.LIMIT":
        errMessage = "Declared value exceeds allowed limit. Please update and try again.";
        break;
      case "ENTERED.AMOUNT.INVALID":
        errMessage = "The entered amount for COD for Package has exceeded the maximum limit. Please update and try again.";
        break;
      case "PACKAGE.DECLAREDVALUE.EXCEEDED":
        errMessage = "Package - Declared value exceeds limit for the packaging type.";
        break;
      case "PACKAGETYPE.DECLAREDVALUE.EXCEEDED":
        errMessage = "Declared value exceeds limit for package. Please update and try again.";
        break;
      case "SERVICE.TYPE.NOTAVAILABLE":
        errMessage = "The requested service type is not supported. Please update and try again.";
        break;
      case "SPECIALSERVICE.PICKUPMETHOD.NOT.ALLOWED":
        errMessage = "This special service type is not allowed with the pickup method. Please update and try again.";
        break;
      case "SHIPMENT.DGHMDIVIOLATIONS.NOTALLOWED":
        errMessage = "This service is not allowed because of dangerous goods or dry ice violations that limit your shipping options. Please update and try again.";
        break;
      case "CURRENCYAMOUNT.CURRENCYTYPE.EXCEEDSLIMIT":
        errMessage = "The total declared value for customs entered exceeds the limit. Please update and try again.";
        break;
      case "SHIPMENT.PACKAGEDIMENSION.INVALID":
        errMessage = "The dimensions entered for this package is invalid.";
        break;
      case "SHIPMENT.POSTALANDCOUNTRYCODE.MISMATCH":
        errMessage = "The postal or routing code and country do not match. Please correct and try again.";
        break;
      case "SHIPMENT.SHIPPERPHONENUMBER.REQUIRED":
        errMessage = "A phone number is required.";
        break;
      case "SHIPMENT.POSTALCODE.INVALID":
        errMessage = "Invalid ZIP/Postal code. Please correct and try again.";
        break;
      case "SHIPMENT.POSTALCODE.NOSERVICE":
        errMessage = "Service is not available to this ZIP/Postal Code. Please select another service or enter an alternate ZIP/Postal Code supported by this service.";
        break;
      case "SHIPMENT.SHIPPINGDOCUMENT.PROHIBITED":
        errMessage = "A FedEx-generated {0} is prohibited for the shipment.";
        break;
      case "SHIPMENT.SERVICETYPE.INVALID":
        errMessage = "Invalid service type";
        break;
      case "SHIPMENT.PACKAGETYPE.INVALID":
        errMessage = "Invalid packaging type";
        break;
      case "SHIPMENT.STATEPOSTAL.MISMATCH":
        errMessage = "The state and postal code do not match.";
        break;
      case "ADDRESS.DETAILS.REQUIRED":
        errMessage = "Address is required";
        break;
      case "ADDRESS.MINIMUMLENGTH.REQUIRED":
        errMessage = "Address must be at least 3 characters.";
        break;
      case "CITYNAME.MINIMUMLENGTH.ERROR":
        errMessage = "City name must be at least 3 characters.";
        break;
      case "COMPANYNAME.MINIMUMLENGTH.ERROR":
        errMessage = "Company name must be at least 3 characters.";
        break;
      case "CONTACT.NAME.REQUIRED":
        errMessage = "Contact name is required";
        break;
      case "CONTACTNAME.MINIMUMLENGTH.ERROR":
        errMessage = "Contact name must be at least 3 characters";
        break;
      case "COUNTRY.LOCATION.REQUIRED":
        errMessage = "Select a Country/Location.";
        break;
      case "CITYNAME.POSTALCODE.INVALID":
        errMessage = "Invalid city name or postal code. Please correct and try again.";
        break;
      case "CITYCODE.ZIPCODE.NOTMATCH":
        errMessage = "ZIP/Postal Code and City do not match. Please correct and try again.";
        break;
      case "SERVICETYPE.PICKUPMETHOD.UNSUPPORTED":
        errMessage = "Service type is not allowed with the pickup method.";
        break;
      case "PHONENUMBER.TOO.SHORT":
        errMessage = "Phone no. must be 10 digits for U.S. and Canada.";
        break;
      case "PHONENUMBER.TOO.LONG":
        errMessage = "Phone number too long.";
        break;
      case "ACCOUNT.NUMBERORKEY.INVALID":
        errMessage = "Invalid account information. Account number and/or key is invalid";
        break;
      case "ANONYMOUS.NOT.ALLOWED":
      case "PERSONNAME.EMPTY":
      case "ACCOUNT.VALIDATION.ERROR":
      case "HAZARDOUSMATERIALS.VIOLATIONS.ERROR":
      case "STREETLINES.TOO.LONG":
      case "PERSONNAME.AND.CONTACTNAME.EMPTY":
        errMessage = "We are unable to process this request. Please try again later or contact FedEx Customer Service.";
        break;
      case "GROUPSHIPMENT.RECIPIENTS.REQUIRED":
      case "GROUPSHIPMENT.RECIPIENTSMAXLIMIT.EXCEEDED":
      case "GROUPSHIPMENT.RECIPIENTCOUNTRIES.INVALID":
      case "GROUPSHIPMENT.REQUESTEDPACKAGELINE.REQUIRED":
      case "GROUPSHIPMENT.SERVICETYPE.INVALID":
      case "GROUPSHIPMENT.SHIPMENTSPECIALSERVICETYPE.INVALID":
      case "GROUPSHIPMENT.PACKAGESPECIALSERVICETYPE.INVALID":
      case "GROUPSHIPMENT.HAZARDOUSCOMMODITY.NOTALLOWED":
        errMessage = "We are unable to process this shipment for the moment. Try again later or contact FedEx Customer Service.";
        break;
      case "PACKAGINGTYPE.INVALID":
        errMessage = "Invalid packaging type.";
        break;
      case "PACKAGING.NOT.ALLOWED":
        errMessage = "Packaging type is not allowed for the shipment. Please update and try again.";
        break;
      case "PACKAGE.DIMENSION.ERROR":
        errMessage = "Dimensions entered are invalid. Please update and try again.";
        break;
      case "SHIPMENTSERVICES.ZIPCODE.NOTAVAILABLE":
        errMessage = "The shipper country is not supported. Please update and try again.";
        break;
      case "SHIPPER.STATEORPROVINCECODE.INVALID":
        errMessage = "Shipper State or Province code is invalid. Please update and try again.";
        break;
      case "SHIPPINGADDRESS.DETAILS.REQUIRED":
        errMessage = "Shipping Address is required. Please update and try again.";
        break;
      case "SHIPPINGDATE.SATURDAYPICKUPONLY.AVAILABLE":
        errMessage = "Saturday Pickup for this service type is not available. Please update and try again.";
        break;
      case "ZIPPOSTALCODE.POSTOFFICE.INVALID":
        errMessage = "The postal code is invalid. Please update and try again.";
        break;
      case "SHIPPING.POSTALCODE.REQUIRED":
        errMessage = "Shipping postal code is required. Please update and try again.";
        break;
      case "RECIPIENTS.ADDRESSCOUNTRYCODE.INVALID":
        errMessage = "Destination country code is invalid. Please update and try again.";
        break;
      case "RECIPIENTS.ADDRESSCOUNTRYCODE.NOTVALID":
        errMessage = "Destination country code must be US for return shipments. Please update and try again.";
        break;
      case "SHIPPER.ADDRESSCOUNTRYCODE.MISSING":
        errMessage = "Origin/shipper country code is invalid or missing. Please update and try again.";
        break;
      case "RECIPIENTS.ADDRESSCOUNTRYCODE.NOTALLOWED":
        errMessage = "Shipments to Syria are not allowed from the specified origin country. Please update and try again.";
        break;
      case "COMMERCIALINVOICE.SHIPMENTPURPOSE.INVALID":
        errMessage = "Missing or invalid shipment purpose for commercial invoice. Please update and try again.";
        break;
      case "RETURNSHIPMENTDETAIL.RETURNTYPE.NOTSUPPORTED":
        errMessage = "Return type not supported. Please update and try again.";
        break;
      case "SHIPPER.ADDRESSCOUNTRYCODE.NOTALLOWED":
        errMessage = "Origin country code must be US for return shipments. Please update and try again.";
        break;
      case "SHIPPER.CONTACTPERSONNAMEORCOMPANYNAME.REQUIRED":
        errMessage = "CompanyName or personName is required. Please update and try again.";
        break;
      case "SHIPPER.CONTACTPHONENUMBER.INVALID":
        errMessage = "Phone number must be valid. Please update and try again.";
        break;
      case "CODRECIPIENT.ADDRESSPOSTALCODE.INVALID":
        errMessage = "Invalid postal code format for COD recipient in special services requested. Please update and try again.";
        break;
      case "CODRECIPIENT.ADDRESSPOSTALCODE.EXCEEDED":
        errMessage = "The length of the postal code exceeds the limit of 16 characters for COD recipient in special services requested. Please update and try again.";
        break;
      case "CODRECIPIENT.ADDRESSSTATEORPROVINCECODE.EXCEEDED":
        errMessage = "The length of the state or province exceeds the limit of 3 characters for COD recipient in special services requested. Please update and try again.";
        break;
      case "REQUESTEDSHIPMENT.TOTALWEIGHT.INVALID":
        errMessage = "Invalid total weight in requested shipment. Please update and try again.";
        break;
      case "SHIPPER.ADDRESSSTATEORPROVINCECODE.EXCEEDED":
        errMessage = "The length of the state or province exceeds the limit of 3 characters. Please update and try again.";
        break;
      case "SHIPPER.STATEORPROVINCECODEANDPOSTALCODE.MISMATCH":
        errMessage = "The state and postal code do not match. Please update and try again.";
        break;
      case "ADDRESS.POSTALCODEANDCITY.MISMATCH":
        errMessage = "The postal code and city in shipper is mismatched. Please update and try again.";
        break;
      case "SHIPPER.ADDRESSSTREETLINES.INVALID":
        errMessage = "Invalid street line 1 in the shipper. Please update and try again.";
        break;
      case "SHIPPER.ADDRESSCITY.INVALID":
        errMessage = "The origin city is invalid. Please update and try again.";
        break;
      case "SHIPPER.ADDRESSPOSTALCODE.NOTFOUND":
        errMessage = "The origin postal code not found. Please update and try again.";
        break;
      case "SHIPPER.ADDRESSPOSTALCODE.INVALIDFORMAT":
        errMessage = "The origin postal code format is invalid. Please update and try again.";
        break;
      case "SHIPPER.ADDRESSPOSTALCODE.NOTSERVED":
        errMessage = "The origin postal code is not served. Please update and try again.";
        break;
      case "SHIPPER.ADDRESSCOUNTRYCODE.NOTSERVED":
        errMessage = "The origin country is not served. Please update and try again.";
        break;
      case "SHIPPER.ADDRESSCOUNTRYCODE.EXCEEDED":
        errMessage = "The shipper country code is required and must not exceed the limit of 2 characters. Please update and try again.";
        break;
      case "SHIPPER.ADDRESSPOSTALCODE.REQUIRED":
        errMessage = "The origin postal code or routing code is required. Please update and try again.";
        break;
      case "SHIPPER.ADDRESSPOSTALCODEANDCOUNTRYCODE.MISMATCH":
        errMessage = "The origin postal or routing code and country do not match. Please update and try again.";
        break;
      case "SHIPPER.ADDRESSPOSTALCODE.EXCEEDED":
        errMessage = "The length of the shipper postal code exceeds the limit of 16 characters. Please update and try again.";
        break;
      case "ORIGIN.CONTACTPERSONNAME.REQUIRED":
        errMessage = "Origin - Company Name OR Person Name is required. Please update and try again.";
        break;
      case "ORIGIN.CONTACTPHONENUMBER.REQUIRED":
        errMessage = "Origin - Phone Number is required. Please update and try again.";
        break;
      case "ORIGIN.CONTACTPHONENUMBER.INVALID":
        errMessage = "Origin - Phone Number is invalid. Please update and try again.";
        break;
      case "ORIGIN.ADDRESS.NOTFOUND":
        errMessage = "Origin - Location Not Found. Please update and try again.";
        break;
      case "ORIGIN.CONTACTCOMPANYNAME.INVALIDLENGTH":
        errMessage = "Origin - Company Name must be at least 2 characters. Please update and try again.";
        break;
      case "ORIGIN.CONTACTPERSONNAME.INVALIDLENGTH":
        errMessage = "Origin - Person Name must be at least 2 characters. Please update and try again.";
        break;
      case "LOCATIONCONTACTANDADDRESS.ADDRESSSTATEORPROVINCE.EXCEEDED":
        errMessage = "The length of the Hold at Location state or province exceeds the limit of 3 characters. Please update and try again.";
        break;
      case "LOCATIONCONTACTANDADDRESS.ADDRESS.NOTFOUND":
        errMessage = "Hold at location in special services requested location not found. Please update and try again.";
        break;
      case "LOCATIONCONTACTANDADDRESS.ADDRESSPOSTALCODE.MISMATCH":
        errMessage = "Hold-at-Location Location-Zip mismatch. Please update and try again.";
        break;
      case "LOCATIONCONTACTANDADDRESS.ADDRESSCITY.REQUIRED":
        errMessage = "City is required for holdAtLocationDetail. Please update and try again.";
        break;
      case "LOCATIONCONTACTANDADDRESS.ADDRESSSTREETLINES.REQUIRED":
        errMessage = "Invalid streetLine for holdAtLocationDetail. Please update and try again.";
        break;
      case "LOCATIONCONTACTANDADDRESS.ADDRESSSTATEORPROVINCE.REQUIRED":
        errMessage = "Invalid stateOrProvinceCode for holdAtLocationDetail. Please update and try again.";
        break;
      case "LOCATIONCONTACTANDADDRESS.ADDRESSPOSTALCODEANDCITY.INVALID":
        errMessage = "Hold at Location Postal-City Mismatch. Please update and try again.";
        break;
      case "SPECIALSERVICETYPES.SIGNATUREOPTIONTYPE.CHANGED":
        errMessage = "Signature Option Type has been changed. Please update and try again.";
        break;
      case "LOCATIONCONTACTANDADDRESS.ADDRESSPOSTALCODE.NOTALLOWED":
        errMessage = "Hold at Location zip is a P.O. Box - Not allowed. Please update and try again.";
        break;
      case "LOCATIONCONTACTANDADDRESS.ADDRESSPOSTALCODE.NOTFOUND":
        errMessage = "Hold-at-Location Postal Code not found. Please update and try again.";
        break;
      case "RECIPIENTS.ADDRESSCOUNTRYCODE.REQUIRED":
        errMessage = "The destination country is required and must not exceed the limit of 2 characters. Please update and try again.";
        break;
      case "RECIPIENTS.ADDRESSSTATEORPROVINCECODE.EXCEEDED":
        errMessage = "The length of the destination state or province exceeds the limit of 3 characters. Please update and try again.";
        break;
      case "PERSONNAME.TOO.LONG":
        errMessage = "Person name too long.";
        break;
      case "CITY.EMPTY":
        errMessage = "City field must contain at least 3 characters.";
        break;
      case "ORGORDEST.SPECIALSERVICES.NOTALLOWED":
        errMessage = "This special service type is not allowed for the origin/destination pair. Please update and try again";
        break;
      default:
        errMessage = `An error occurred. Please check your input and try again. (${ex.message})`
        break;
    }
  }
  showError(errMessage);
};