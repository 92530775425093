import { route, routes } from "helpers/routeHelper";
import Dashboard from "pages/Dashboard/index";
import LeaderboardList from "pages/Dashboard/Leaderboard";
import UserList from "pages/User/List";
import UserView from "pages/User/View";
import UserNew from "pages/User/New";
import NotificationList from "pages/Notification/List";
import NotificationView from "pages/Notification/View";
import DealerGroupList from "pages/DealerGroup/List";
import DealerGroupView from "pages/DealerGroup/View";
import DealerGroupNew from "pages/DealerGroup/New";
import DealerGroupNewManager from "pages/DealerGroup/New/Manager";
import DealerRegionView from "pages/DealerRegion/View";
import DealerRegionNew from "pages/DealerRegion/New";
import DealerRegionNewManager from "pages/DealerRegion/New/Manager";
import DealerStoreList from "pages/DealerStore/List";
import DealerStoreView from "pages/DealerStore/View";
import DealerStoreNew from "pages/DealerStore/New/index";
import DealerStoreNewUser from "pages/DealerStore/New/User";
import OrderList from "pages/Order/List";
import OrderViewIndex from "pages/Order/View";
import OrderViewVid from "pages/Order/View/Vid";
import OrderViewESign from "pages/Order/View/ESign";
import OrderViewInkSign from "pages/Order/View/InkSign";
import OrderViewFinance from "pages/Order/View/Finance";
import OrderViewSupporting from "pages/Order/View/Supporting";
import OrderViewActivity from "pages/Order/View/Activity";
import OrderViewMessages from "pages/Order/View/Messages";
import OrderViewScheduler from "pages/Order/View/Scheduler";
import OrderViewNotary from "pages/Order/View/Notary";
import OrderViewNotaryInformation from "pages/Order/View/NotaryInformationStatus";
import OrderViewNotaryManualSearch from "pages/Order/View/NotaryManualSearch";
import OrderViewBiddingLogs from "pages/Order/View/BiddingLogs";
import OrderViewAccountingEdit from "pages/Order/Partial/Form/Edit/Accounting/AccountingEdit";
import Seal from "pages/Order/View/Seal";
import PaymentPlanList from "pages/PaymentPlan/List";
import PaymentPlanView from "pages/PaymentPlan/View";
import PaymentPlanNew from "pages/PaymentPlan/New";
import DealerStoreSignerNew from "pages/DealerStoreSigner/New";
import DealerStoreSignerView from "pages/DealerStoreSigner/View";
import MessageList from "pages/Message/List";
import SupportCaseList from "pages/SupportCase/List";
import SupportCaseView from "pages/SupportCase/View";
import NotaryList from "pages/Notary/List";
import NotaryView from "pages/Notary/View";
import AdditionalFeeList from "pages/AdditionalFee/List";
import AdditionalFeeView from "pages/AdditionalFee/View";
import PaymentPlanFeeNew from "pages/PaymentPlan/Partial/Form/CustomFee/New";
import PaymentPlanFeeView from "pages/PaymentPlan/CustomFees/View";
import SchedulerPerformanceReport from "pages/PerformanceReport/Scheduler";
import Quickbooks from "../pages/Quickbooks/View/Quickbooks";
import PaymentsList from "pages/Payments/List";
import PaymentView from "pages/Payments/View";
import DealerStatementList from "pages/Statement/Dealer/List";
import DealerStatementView from "pages/Statement/Dealer/View";
import NotaryStatementList from "pages/Statement/Notary/List";
import NotaryStatementView from "pages/Statement/Notary/View";
import BiddingsList from 'pages/Bidding/List';
import GroupUsageReport from "pages/GroupUsageReport/GroupUsage";
import CronJobList from "pages/CronJob/List";
import CronJobView from "pages/CronJob/View";
import ErrorCodesList from "pages/Dev/ListErrCodes";
import SysProcessList from "pages/Dev/ListProcesses";
import SysProcessView from "pages/Dev/ViewProcess";
import PendingReviewOrderList from "pages/PendingReview/List";
import RemoteOrderList from "pages/Order/Remote/List";
import InStoreOrderList from "pages/Order/InStore/List";
import DealerSalesReport from "pages/DealerSalesReport/DealerSales";
import NewDealersReport from "pages/NewDealersReport/NewDealers";
import HelpList from "pages/Help/List";
import OrderViewChannel from "pages/Order/View/Channel";
import RouteOne from "pages/MarketingPages/RouteOne";
import RouteOneLogsList from "pages/Dev/ListRouteOneLogs";
import RouteOneLogView from "pages/Dev/Partial/View/RouteOneLogInfo";
import GenerateLabelIndex from "pages/Order/Partial/GenerateLabel";
import Fedex from "pages/MarketingPages/Fedex";

const publicRoutes = [

];

const protectedRoutes = [
  { path: route(routes.list_users), component: UserList },
  { path: route(routes.view_user), component: UserView },
  { path: route(routes.new_user), component: UserNew },

  { path: route(routes.list_dealer_groups), component: DealerGroupList },
  { path: route(routes.view_dealer_group), component: DealerGroupView },
  { path: route(routes.new_dealer_group), component: DealerGroupNew },
  { path: route(routes.new_dealer_group_region), component: DealerRegionNew },
  { path: route(routes.new_dealer_group_manager), component: DealerGroupNewManager },

  { path: route(routes.view_dealer_region), component: DealerRegionView },
  { path: route(routes.new_dealer_region_manager), component: DealerRegionNewManager },

  { path: route(routes.list_dealer_stores), component: DealerStoreList },
  { path: route(routes.view_dealer_store), component: DealerStoreView },
  { path: route(routes.new_dealer_store), component: DealerStoreNew },
  { path: route(routes.new_dealer_store_user), component: DealerStoreNewUser },
  { path: route(routes.new_dealer_store_signer), component: DealerStoreSignerNew },

  { path: route(routes.view_dealer_store_signer), component: DealerStoreSignerView },

  { path: route(routes.list_notaries), component: NotaryList },

  { path: route(routes.list_notifications), component: NotificationList },
  { path: route(routes.view_notification), component: NotificationView },

  { path: route(routes.list_orders), component: OrderList },
  { path: route(routes.list_orders_remote), component: RemoteOrderList },
  { path: route(routes.list_orders_in_store), component: InStoreOrderList },
  { path: route(routes.view_order), component: OrderViewIndex },
  { path: route(routes.view_order_vid), component: OrderViewVid },
  { path: route(routes.view_order_e_sign), component: OrderViewESign },
  { path: route(routes.view_order_ink_sign), component: OrderViewInkSign },
  { path: route(routes.view_order_finance), component: OrderViewFinance },
  { path: route(routes.view_order_supporting), component: OrderViewSupporting },
  { path: route(routes.view_order_activity), component: OrderViewActivity },
  { path: route(routes.view_order_messages), component: OrderViewMessages },
  { path: route(routes.view_order_channel), component: OrderViewChannel },
  { path: route(routes.view_order_scheduler), component: OrderViewScheduler },
  { path: route(routes.view_order_notary), component: OrderViewNotary },
  { path: route(routes.view_order_notary_manual_search), component: OrderViewNotaryManualSearch },
  { path: route(routes.view_order_notary_information), component: OrderViewNotaryInformation },
  { path: route(routes.view_order_bidding_logs), component: OrderViewBiddingLogs },
  { path: route(routes.list_pending_review_orders), component: PendingReviewOrderList },
  { path: route(routes.view_order_generate_label), component: GenerateLabelIndex },

  { path: route(routes.view_quickbooks), component: Quickbooks },

  { path: route(routes.view_accounting_edit), component: OrderViewAccountingEdit },

  { path: route(routes.view_seal), component: Seal },

  { path: route(routes.view_notary), component: NotaryView },

  { path: route(routes.view_scheduler_performance_report), component: SchedulerPerformanceReport },
  { path: route(routes.view_group_usage_report), component: GroupUsageReport },
  { path: route(routes.view_dealer_sales_report), component: DealerSalesReport },
  { path: route(routes.view_new_dealers_report), component: NewDealersReport },

  { path: route(routes.list_payment_plans), component: PaymentPlanList },
  { path: route(routes.view_payment_plan), component: PaymentPlanView },
  { path: route(routes.new_payment_plan), component: PaymentPlanNew },
  { path: route(routes.new_payment_plan_fee), component: PaymentPlanFeeNew },
  { path: route(routes.view_payment_plan_fee), component: PaymentPlanFeeView },

  { path: route(routes.list_messages), component: MessageList },

  { path: route(routes.list_leaderboard), component: LeaderboardList },

  { path: route(routes.list_support_cases), component: SupportCaseList },
  { path: route(routes.view_support_case), component: SupportCaseView },

  { path: route(routes.home), component: Dashboard },

  { path: route(routes.list_additional_fees), component: AdditionalFeeList },
  { path: route(routes.view_additional_fee), component: AdditionalFeeView },

  { path: route(routes.list_payments), component: PaymentsList },
  { path: route(routes.view_payment), component: PaymentView },

  { path: route(routes.list_dealer_statements), component: DealerStatementList },
  { path: route(routes.view_dealer_statement), component: DealerStatementView },
  { path: route(routes.list_notary_statements), component: NotaryStatementList },
  { path: route(routes.view_notary_statement), component: NotaryStatementView },
  { path: route(routes.view_biddings), component: BiddingsList },

  { path: route(routes.list_cron_jobs), component: CronJobList },
  { path: route(routes.view_cron_job), component: CronJobView },
  { path: route(routes.list_error_codes), component: ErrorCodesList },
  { path: route(routes.list_sys_processes), component: SysProcessList },
  { path: route(routes.view_sys_process), component: SysProcessView },
  { path: route(routes.list_route_one_logs), component: RouteOneLogsList },
  { path: route(routes.view_route_one_log), component: RouteOneLogView },

  { path: route(routes.list_help), component: HelpList },

  { path: route(routes.route_one), component: RouteOne },

  { path: route(routes.view_fedex), component: Fedex },
];

export { publicRoutes, protectedRoutes }
