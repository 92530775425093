import React, { useState } from "react"
import { Container, Card, UncontrolledAccordion, AccordionBody, AccordionItem, AccordionHeader } from"reactstrap";
import { fedexSetup } from "./../constants";
import MetaTitle from "components/Shared/MetaTitle";
import Banner from "../Partial/Section/Banner";
import AccordionRow from "../Partial/Section/AccordionRow";
import Video from "../Partial/Section/Video";


const Fedex = () => {
  // Set the first accordeon open by default
 const [open, setOpen] = useState(true);

  const toggle = id => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };


  return <React.Fragment>
    <div className="page-content">
      <MetaTitle>FedEx</MetaTitle>
      <Container className="marketing-page" fluid>
        <Card className="height-100">
          <Banner path="fedex" />

          <div className="marketing-page-accordion-container mt-5">
            <UncontrolledAccordion className="mb-3" open={open} toggle={toggle} defaultOpen={['fedex-setup']} stayOpen >
              <AccordionItem key='fedex-setup'>
                <AccordionHeader targetId='fedex-setup'>
                  <div>
                    <h6 className="accordion-title m-0">How to use FedEx labels on your Mavsign orders:</h6>
                    <p className="accordion-description mt-3">{`Welcome to Mavsign! In this tutorial, we'll walk you through how to use FedEx together with Mavsign to save time when generating outgoing and return labels for your orders. Right now, this feature is available only for Notary-Assisted orders. Let's dive in!`}</p>
                  </div>
                </AccordionHeader>
                <AccordionBody accordionId='fedex-setup'>
                  <Video url={'https://www.youtube.com/embed/3bVfSY3Axn4?si=YrnBl8hcRlxdWy2e'} />
                  {fedexSetup.map((item, index) => <AccordionRow key={index} title={item.title} description={item.description} />)}
                </AccordionBody>
              </AccordionItem>
            </UncontrolledAccordion>
          </div>
        </Card>
      </Container>
    </div>
  </React.Fragment>
}

export default Fedex;
