import React, { useEffect, useState } from "react";
import { Card, CardBody, Row, Col, CardHeader, CardTitle, Alert } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import { getSharedPaginationOptions } from "helpers/utilHelper";
import { Link } from "react-router-dom";
import { doNotaryStatementDtCleanup, getNotaryStatementDt } from "store/actions";
import SpinnerChase from "components/Shared/SpinnerChase";
import BootstrapTable from "react-bootstrap-table-next";
import { route, routes } from "helpers/routeHelper";
import { formatTimestamp, formats } from "helpers/dateHelper";
import PropTypes from "prop-types";

const SectionAccounting = ({ notary }) => {
  const dispatch = useDispatch();

  /********** STATE **********/

  const { statements: rows, statementsError: rowsError, totalCount, listParams, isLoadInProgress } = useSelector(state => state.NotaryStatement.Dt);

  // PaginationProvider options
  const [paginationOptions, setPaginationOptions] = useState({
    ...getSharedPaginationOptions(),
    totalSize: totalCount,
    page: listParams.page,
    sizePerPage: 5,
    sizePerPageList: [
      { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '15', value: 15 },
      { text: '20', value: 20 },
    ],
  });

  /********* EFFECTS *********/

  // runs once on component mount
  useEffect(() => {
    dispatch(getNotaryStatementDt({
      ...listParams,
      filters: {
        notaryId: notary.id
      }
    }));
    return () => {
      // state cleanup on component unmount
      dispatch(doNotaryStatementDtCleanup());
    }
  }, []);

  // runs whenever "totalCount" changes
  // which happens after the first remote call
  useEffect(() => {
    // now we know the total number of rows so let"s update the pagination
    setPaginationOptions(options => ({
      ...options,
      totalSize: totalCount,
    }));
  }, [totalCount]);

  /********** EVENT HANDLERS **********/

  // runs whenever table params change (pagination, etc)
  const handleTableChange = (type, newState) => {
    // refresh the list data based on the new table params
    dispatch(getNotaryStatementDt({
      ...listParams,
      pageSize: newState.sizePerPage,
      page: newState.page,
      filters: {
        notaryId: notary.id
      }
    }));
    // update pagination
    setPaginationOptions(options => ({
      ...options,
      page: newState.page,
      sizePerPage: newState.sizePerPage,
    }));
  };

  return (<Card className="expand-v">
    <CardHeader className="bg-transparent pt-3 pb-0">
      <Row>
        <Col>
          <CardTitle>Accounting Information</CardTitle>
        </Col>
      </Row>
    </CardHeader>
    <CardBody>
      <Row>
        {(
          <PaginationProvider pagination={paginationFactory(paginationOptions)}>
            {({ paginationProps, paginationTableProps }) => (
              <>
                <Row>
                  <Col>
                    <div className="table-responsive">
                      <BootstrapTable
                        bordered={false}
                        striped={false}
                        keyField='statementId'
                        columns={getColumns(listParams.pageSize, listParams.page)}
                        data={rows}
                        noDataIndication={!rowsError && "No statements found"}
                        onTableChange={handleTableChange}
                        {...paginationTableProps}
                      />
                    </div>
                    {isLoadInProgress && <SpinnerChase className="sm dtable" />}
                    {!!rowsError && <Alert color="danger" className="fade show text-center">
                      <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load statements
                    </Alert>}
                  </Col>
                </Row>
                <Row className="align-items-md-center mt-3">
                  <Col className="inner-custom-pagination d-flex">
                    <div className="d-inline">
                      <SizePerPageDropdownStandalone
                        {...paginationProps}
                        variation="dropup"
                      />
                    </div>
                    <div className="text-md-right ms-auto">
                      <PaginationListStandalone
                        {...paginationProps}
                      />
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </PaginationProvider>
        )}
      </Row>
    </CardBody>
  </Card>)
}

const getColumns = (pageSize, page) => ([{
  dataField: 'idx',
  text: 'No.',
  formatter: (cellContent, row, rowIndex) => pageSize * (page - 1) + rowIndex + 1,
}, {
  dataField: "id",
  text: "Statement ID",
  // eslint-disable-next-line react/display-name
  formatter: cellContent => <Link to={route(routes.view_notary_statement, cellContent)}>{cellContent}</Link>,
}, {
  dataField: "orderId",
  text: "Order ID",
}, {
  dataField: "createdTs",
  text: "Date",
  formatter: cellContent => formatTimestamp(cellContent, formats.LONG_DATE),
}, {
  dataField: "customerName",
  text: "Customer Name",
}, {
  dataField: "amount",
  text: "Amount",
  formatter: cellContent => `$${cellContent}`,
}]);

SectionAccounting.propTypes = {
  notary: PropTypes.object,
};

export default SectionAccounting;