import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import SpinnerChase from "components/Shared/SpinnerChase";
import { Link, } from "react-router-dom";
import { Card, CardBody, Row, Col, Alert, UncontrolledTooltip } from "reactstrap";
import { getDtFooterRowCount, getGranularStatusName, getSharedTableOptions, getVIDStatusImage } from "helpers/utilHelper";
import { formatTimestamp, formatTimestampTz, formats, getPassedDuration, timestamp } from "helpers/dateHelper";
import { getSharedPaginationOptions } from "helpers/utilHelper";
import { route, routes } from "helpers/routeHelper";
import DataTableFilters from '../Filters';
import { getOrderDt, doOrderDtCleanup } from 'store/order/actions';
import Order from 'model/order';
import inkSignServiceIcon from 'assets/images/ink-sign-service-icon.svg';
import eSignServiceIcon from 'assets/images/e-sign-service-icon.svg';
import notaryServiceIcon from 'assets/images/notary-service-icon.svg';
import rushOrderServiceIcon from 'assets/images/rush-order-icon.svg';
import docDeliveryShippingIcon from 'assets/images/delivery-shipping.svg';
import docDeliveryUploadIcon from 'assets/images/delivery-upload.svg';
import coSigners from 'assets/images/co-signer-icon.svg';
import fraudIcon from 'assets/images/internal-status-fraud.svg';
import highRiskIcon from 'assets/images/internal-status-high-risk.svg';
import onHoldIcon from 'assets/images/internal-status-on-hold.svg';
import underReviewIcon from 'assets/images/internal-status-under-review.svg';
import inactiveIcon from 'assets/images/inactive-order.svg';
import ruralIcon from 'assets/images/rural-order.svg';
import mustUploadDocsIcon from 'assets/images/notary-must-upload-docs.svg';
import supportIcon from 'assets/images/support-icon.svg';
import noThumbprintIcon from 'assets/images/no-thumbprint-icon.svg';
import routeOneIcon from 'assets/images/route-one.svg';
import generateLabelIcon from 'assets/images/generate-label-icon.svg';
import { omit } from 'lodash';
import config from 'config';
import { useAuth } from "context/auth";
import UserRole from 'model/userRole';
import { useAccess } from 'context/access';
import newDealershipIcon from 'assets/images/new-icon.svg';

const DataTable = () => {

  const dispatch = useDispatch();
  const { user } = useAuth();
  const { iAmScheduler } = useAccess();


  /********** STATE **********/

  const { orders: rows, ordersError: rowsError, totalCount, listParams, isLoadInProgress } = useSelector(state => state.Order.Dt);
  const filters = useSelector(state => state.Order.DtFilters);

  // datatable PaginationProvider options
  const [paginationOptions, setPaginationOptions] = useState({
    ...getSharedPaginationOptions(),
    totalSize: totalCount,
    page: listParams.page,
    sizePerPage: listParams.pageSize,
    defaultSorted: [{
      dataField: listParams.sortBy,
      order: listParams.sortDir,
    }],
  });

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // we do not get the list data here
    // instead we listen for changes on 'filters' state var and do it there (see below)
    // this is to avoid fetching the data twice (both on component mount and on filters changed)
    return () => {
      // state cleanup on component unmount
      dispatch(doOrderDtCleanup());
    }
  }, []);

  // runs whenever 'totalCount' changes
  // which happens after the first remote call
  useEffect(() => {
    // now we know the total number of rows so let's update the pagination
    setPaginationOptions(options => ({
      ...options,
      totalSize: totalCount,
    }));
  }, [totalCount]);

  // runs whenever 'filters' changes
  // which happens after 'apply-filters' or 'clear-filters'
  // but also on component mount
  useEffect(() => {
    // '_set' is a special flag we use to know if the default filter values have been initialized (ex. from url)
    if (filters.hasOwnProperty('_set') && !filters._set) {
      return;
    }
    // refresh the list data based on the new filters
    dispatch(getOrderDt({
      ...listParams,
      // '_set' is a special flag we use to know if the default filter values have been initialized
      // we do not want that passed to back-end
      filters: omit(filters, '_set'),
      // reset the page number when filtering
      // otherwise the current page number might be higher than the total number of pages after the filtering
      page: 1,
    }));
    // update the pagination with the new page number
    setPaginationOptions(options => ({
      ...options,
      page: 1,
    }));
  }, [filters]);


  /********** EVENT HANDLERS **********/

  // runs whenever table params change (sorting, pagination, search, etc)
  const handleTableChange = (type, newState) => {
    // '_set' is a special flag we use to know if the default filter values have been initialized (ex. from url)
    if (filters.hasOwnProperty('_set') && !filters._set) {
      return;
    }
    // reset the page number when searching
    // otherwise the current page number might be higher than the total number of pages after the search
    if (type == 'search') {
      newState.page = 1;
    }
    // refresh the list data based on the new table params
    dispatch(getOrderDt({
      ...listParams,
      sortBy: newState.sortField,
      sortDir: newState.sortOrder,
      pageSize: newState.sizePerPage,
      page: newState.page,
      search: newState.searchText,
    }));
    // update pagination
    setPaginationOptions(options => ({
      ...options,
      page: newState.page,
      sizePerPage: newState.sizePerPage,
    }));
  };

  return <>
    <Card className="paginated-table-card">
      <CardBody className="pt-3">
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => (
            <ToolkitProvider
              keyField='id'
              columns={columns(iAmScheduler())}
              data={user.userRoleId === UserRole.ID_SCHEDULER ? rows.filter(row => user.id === row.schedulerId) : rows}
              search={{ defaultSearch: listParams.search }}>
              {toolkitProps => (
                <>
                  <Row className="mb-2">
                    <Col>
                      <div className='d-flex align-items-center'>
                        <div className="search-box d-inline-block">
                          <div className="position-relative">
                            <Search.SearchBar
                              {...toolkitProps.searchProps}
                              delay={config.DATA_TABLE_SEARCH_DELAY}
                            />
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col sm="auto">
                      <div className="text-end">
                        <DataTableFilters />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="table-responsive">
                        <BootstrapTable
                          {...getSharedTableOptions()}
                          noDataIndication={!rowsError && "No orders found"}
                          onTableChange={handleTableChange}
                          defaultSorted={paginationProps.defaultSorted}
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                        />
                      </div>
                      {isLoadInProgress && <SpinnerChase className="sm dtable" />}
                      {!!rowsError && <Alert color="danger" className="fade show text-center">
                        <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load orders
                      </Alert>}
                    </Col>
                  </Row>
                  <Row className="align-items-md-center mt-3">
                    <Col className="inner-custom-pagination d-flex">
                      <div className="d-inline">
                        <SizePerPageDropdownStandalone
                          {...paginationProps}
                          variation="dropup"
                        />
                        <div className="d-inline ms-2">{getDtFooterRowCount(paginationProps, rows.length, totalCount)}</div>
                      </div>
                      <div className="text-md-right ms-auto">
                        <PaginationListStandalone
                          {...paginationProps}
                        />
                      </div>
                    </Col>
                  </Row>
                </>
              )}
            </ToolkitProvider>
          )}
        </PaginationProvider>
      </CardBody>
    </Card>
  </>
}

const columns = (iAmScheduler) => [{
  dataField: 'id',
  text: 'Signing ID',
  sort: true,
  // eslint-disable-next-line react/display-name
  formatter: (cellContent, row) => <div><Link to={route(routes.view_order, row.id)} className="me-2">{cellContent}</Link>{row.unreadMessagesCount > 0 && <span className={`badge badge-sm rounded-pill bg-cancelled`}>{row.unreadMessagesCount}</span>}</div>,
}, {
  dataField: 'customerName',
  text: 'Customer Name',
  sort: true,
}, {
  dataField: 'dummy3',
  text: 'Services included',
  // eslint-disable-next-line react/display-name
  formatter: (cellContent, row) => <div>
    {row.isVidRequired && getVIDStatusImage(row.vidRequestStatus, row)}
    {row.isEsignRequired && <><img id={`e-sign${row.id}`} src={eSignServiceIcon} className="me-2" /><UncontrolledTooltip placement="top" target={`e-sign${row.id}`}>E sign</UncontrolledTooltip></>}
    {row.isInkSignRequired && <><img id={`ink-sign${row.id}`} src={inkSignServiceIcon} className="me-2" /><UncontrolledTooltip placement="top" target={`ink-sign${row.id}`}>Ink sign</UncontrolledTooltip></>}
    {row.isNotaryRequired && <><img id={`notary-service${row.id}`} src={notaryServiceIcon} className="me-2" /><UncontrolledTooltip placement="top" target={`notary-service${row.id}`}>Notary service</UncontrolledTooltip></>}
    {row.hasAdditionalSigner && <><img id={`co-signers${row.id}`} src={coSigners} className="me-2" /><UncontrolledTooltip placement="top" target={`co-signers${row.id}`}>Co-signers</UncontrolledTooltip></>}
    {row.docDeliveryOption == Order.DOC_DELIVERY_OPTION_SHIPPING &&
      <><img id={`doc-delivery-shipping${row.id}`} src={docDeliveryShippingIcon} className="me-2" /><UncontrolledTooltip placement="top" target={`doc-delivery-shipping${row.id}`}>Documents shipping</UncontrolledTooltip></>
    }{row.docDeliveryOption == Order.DOC_DELIVERY_OPTION_UPLOAD &&
      <><img id={`doc-delivery-upload${row.id}`} src={docDeliveryUploadIcon} className="me-2" /><UncontrolledTooltip placement="top" target={`doc-delivery-upload${row.id}`}>Documents upload</UncontrolledTooltip></>
    }
    {row.isRushRequired && <><img id={`rush-order${row.id}`} src={rushOrderServiceIcon} className="me-2" /><UncontrolledTooltip placement="top" target={`rush-order${row.id}`}>Rush order</UncontrolledTooltip></>}
    {row.notaryMustUploadDocs && <><img id={`must-upload-docs${row.id}`} src={mustUploadDocsIcon} className="me-2 must-upload-icon" /><UncontrolledTooltip placement="top" target={`must-upload-docs${row.id}`}>Notary must upload docs</UncontrolledTooltip></>}
    {!row.isAoiThumbprintRequired && row.isNotaryRequired && <><img id={`no-thumbprint-aoi${row.id}`} src={noThumbprintIcon} className="me-2" /><UncontrolledTooltip placement="top" target={`no-thumbprint-aoi${row.id}`}>No Thumbprint AOI</UncontrolledTooltip></>}
    {row.r1DealJacketId && <><img id={`route-one${row.id}`} src={routeOneIcon} className="me-2" /><UncontrolledTooltip placement="top" target={`route-one${row.id}`}>Route One</UncontrolledTooltip></>}
    {row.hasMavShippingLabel && row.isNotaryRequired && <><img id={`generate-label${row.id}`} src={generateLabelIcon} className="me-2" /><UncontrolledTooltip placement="top" target={`generate-label${row.id}`}>Label Generation</UncontrolledTooltip></>}
  </div>,
}, {
  dataField: 'createdTs',
  text: 'Date ordered',
  sort: true,
  // eslint-disable-next-line react/display-name
  formatter: (cellContent, row) => formatTimestamp(cellContent, formats.DATETIME),
}, {
  dataField: 'granularStatusSortOrder',
  text: 'Status',
  sort: true,
  // eslint-disable-next-line react/display-name
  formatter: (_cellContent, row) => {
    const color = Order.getGranularStatusColor(row.granularStatus);
    return <span className={`badge badge-lg w-100 rounded-pill bg-${color}`}>{getGranularStatusName(row.granularStatus)}</span>;
  },
}, {
  dataField: 'dummy4',
  text: 'Alerts',
  // eslint-disable-next-line react/display-name
  formatter: (cellContent, row) => {
    let timePassed = "";
    const { days, hours } = getPassedDuration(row.updatedTs, timestamp())

    if (days > 0) {
      timePassed = timePassed + `${days}d`
    }
    if (hours > 0) {
      timePassed = timePassed + `${hours}h`
    }

    // don't show inactive alert for canceled orders
    if (!!row.internalStatus || (row.isInactive && row.status != Order.STATUS_CANCELLED) || row.isRural || !!row.hasOpenSupportCase) return (<div>
      {row.internalStatus === Order.INTERNAL_STATUS_FRAUD && <><img id={`internal-status-${row.id}`} src={fraudIcon} className="me-2" /><UncontrolledTooltip
        placement="top" target={`internal-status-${row.id}`}>Fraud</UncontrolledTooltip></>}
      {row.internalStatus === Order.INTERNAL_STATUS_HIGH_RISK && <><img id={`internal-status-${row.id}`} src={highRiskIcon} className="me-2" /><UncontrolledTooltip
        placement="top" target={`internal-status-${row.id}`}>High Risk</UncontrolledTooltip></>}
      {row.internalStatus === Order.INTERNAL_STATUS_ON_HOLD && <><img id={`internal-status-${row.id}`} src={onHoldIcon} className="me-2" /><UncontrolledTooltip
        placement="top" target={`internal-status-${row.id}`}>On Hold</UncontrolledTooltip></>}
      {row.internalStatus === Order.INTERNAL_STATUS_UNDER_REVIEW && <><img id={`internal-status-${row.id}`} src={underReviewIcon} className="me-2" /><UncontrolledTooltip
        placement="top" target={`internal-status-${row.id}`}>Under Review</UncontrolledTooltip></>}
      {row.isInactive && row.status != Order.STATUS_CANCELLED && <><img id={`inactive${row.id}`} src={inactiveIcon} className="me-2" /><UncontrolledTooltip
        placement="top" target={`inactive${row.id}`}>Inactive({timePassed})</UncontrolledTooltip></>}
      {!!row.isRural && <><img id={`rural${row.id}`} src={ruralIcon} className="me-2" /><UncontrolledTooltip
        placement="top" target={`rural${row.id}`}>Rural</UncontrolledTooltip></>}
      {!!row.hasOpenSupportCase && <><img id={`support-case${row.id}`} src={supportIcon} className="me-2" /><UncontrolledTooltip
        placement="top" target={`support-case${row.id}`}>Support case</UncontrolledTooltip></>}
    </div>)
    return <span>--</span>
  }
}, {
  dataField: 'dealerStoreName',
  text: 'Dealership',
  sort: true,
  // eslint-disable-next-line react/display-name
  formatter: (cellContent, row) => <div className='d-flex'>
    <Link to={route(routes.view_dealer_store, row.dealerStoreId)}>{cellContent}</Link>
    {row.isNewDealerStore && <div className='ms-2'>
      <img id={`new-dealership-${row.id}`} src={newDealershipIcon} className="me-2" />
      <UncontrolledTooltip placement="top" target={`new-dealership-${row.id}`}>New Dealership <br />{row.dealerStoreAge === 0 ? 'less than a day' : row.dealerStoreAge + ' days'}</UncontrolledTooltip>
    </div>}
  </div>
}, {
  dataField: 'creatorFullName',
  text: 'Creator',
  sort: true,
  // eslint-disable-next-line react/display-name
  formatter: cellContent => <span>{cellContent}</span>
},
{
  dataField: 'schedulerFullName',
  text: 'Scheduler',
  sort: true,
  hidden: iAmScheduler,
  // eslint-disable-next-line react/display-name
  formatter: (cellContent, row) => {
    if (!!row.schedulerFullName) {
      return <Link to={route(routes.view_user, row.schedulerId)}>{cellContent}</Link>;
    }
    return <span>--</span>;
  },
}, {
  dataField: 'notaryMeetingTs',
  text: 'Meeting time',
  sort: true,
  // eslint-disable-next-line react/display-name
  formatter: (cellContent, row) => {
    if (!row.notaryId) {
      return <span>N/A</span>
    }
    return <span>{cellContent ? formatTimestampTz(cellContent, formats.DATETIME, row.notaryTimezone) : 'Not set'}</span>
  },
}, {
  dataField: 'notaryFullName',
  text: 'Notary',
  sort: true,
  // eslint-disable-next-line react/display-name
  formatter: (cellContent, row) => {
    if (row.notaryFullName) {
      return <Link to={route(routes.view_notary, row.notaryId)}>{cellContent}</Link>;
    }
    return <span>--</span>
  },
}, {
  dataField: 'billingStatus',
  text: 'Billing status',
  sort: false,
  // eslint-disable-next-line react/display-name
  formatter: cellContent => <span>{cellContent ? Order.getBillingStatusName(cellContent) : '--'}</span>
}];

export default DataTable;
