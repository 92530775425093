import React, { useState } from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Row, CardHeader, Table, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import Col from "components/Shared/Col";
import { perms, useAccess } from "context/access";
import CardPreloader from "components/Shared/CardPreloader";
import shield from "assets/images/shield-blue.svg";
import Order from "model/order";
import { formats, formatTimestamp } from "helpers/dateHelper";

const ViewContract = props => {

  const { isRefreshing, order, toggleEditMode, isLocked, id } = props;

  // hooks that check permissions
  const { iAmGranted } = useAccess();

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const getInfoMessageText = () => order.docDeliveryOption === Order.DOC_DELIVERY_OPTION_SHIPPING ? "You can generate an Outgoing/Return label after a notary is assigned to the order" : "You can generate a Return label after a notary is assigned to the order";

  return <React.Fragment>
    <Card className="expand-v overflow-hidden">
      <CardHeader className="bg-transparent pt-3 pb-0">
        <Row>
          <Col>
            <div className="card-title mt-2 mb-0">Contract Information</div>
          </Col>
          {iAmGranted(perms.edit_orders) && !isLocked && <Col xs="auto" className="text-end">
            <Dropdown isOpen={menuIsOpen} toggle={() => setMenuIsOpen(!menuIsOpen)}>
              <DropdownToggle tag="button" className="btn btn-default card-menu-btn">
                <i className="bx bx-dots-horizontal-rounded" />
              </DropdownToggle>
              <DropdownMenu end>
                <DropdownItem onClick={toggleEditMode}>Edit</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Col>}
          {isLocked && <Col xs="auto" className="text-end">
            <img src={shield} className="mt-1" />
          </Col>}
        </Row>
      </CardHeader>
      <CardBody>
        <Table className="section-rows table bt-1 bb-0">
          <tbody>
            {order.docDeliveryOption === Order.DOC_DELIVERY_OPTION_SHIPPING && (
              <>
                <tr>
                  <td className="section-row-label pe-1">Date when documents will be sent to notary</td>
                  <td className="section-row-value">{formatTimestamp(order.docsSentToNotaryTs, formats.US_DATE)}</td>
                </tr>
              </>
            )}
            <tr>
              <td className="section-row-label">Items to collect</td>
              <td className="section-row-value">{!!order.itemsToCollect ? order.itemsToCollect.map(item => Order.getItemToCollectName(item)).join(", ") : "--"}</td>
            </tr>
            <tr>
              <td className="section-row-label">Additional items to collect</td>
              <td className="section-row-value">{order.additionalItemsToCollect || "--"}</td>
            </tr>
            <tr>
              <td className="section-row-label">Dealer special instructions</td>
              <td className="section-row-value">{order.dealerInstructions || "--"}</td>
            </tr>
            <tr>
              <td className="section-row-label">Does the notary need to scan and upload signed DOCS?</td>
              <td className="section-row-value">{order.notaryMustUploadDocs ? 'Yes' : 'No'}</td>
            </tr>
            {!!order.signers && order.signers.length > 1 && <>
              <tr>
                <td className="section-row-label">Specific signing instructions. Signer 1</td>
                <td className="section-row-value">{order.signer1Instructions || "--"}</td>
              </tr>
              <tr>
                <td className="section-row-label">Specific signing instructions. Signer 2</td>
                <td className="section-row-value">{order.signer2Instructions || "--"}</td>
              </tr>
            </>}
            <tr>
              <td className="section-row-label">Thumbprint AOI</td>
              <td className="section-row-value">{order.isAoiThumbprintRequired ? "Yes" : "No"}</td>
            </tr>
            <tr>
              <td className="section-row-label">Label Generation</td>
              <td className="section-row-value">{order.hasMavShippingLabel ? "Label generated with Mavsign" : "Label generated by the dealership"}</td>
            </tr>
          </tbody>
        </Table>
      </CardBody>
      {isRefreshing && <CardPreloader />}
    </Card>
  </React.Fragment>
}

ViewContract.propTypes = {
  isRefreshing: PropTypes.bool,
  order: PropTypes.object,
  toggleEditMode: PropTypes.func,
  isLocked: PropTypes.bool,
  id: PropTypes.number,
};

export default ViewContract;
