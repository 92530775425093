import React from "react";
import PropTypes from 'prop-types';

const Banner = ({ path }) => {

  const getImage = (size) => require(`assets/images/marketing/${path}/banner-${size}.png`).default;

  return (
    <div className="marketing-page-banner">
      <picture>
        <source media="(min-width: 2500px)" srcSet={getImage("xxl")} />
        <source media="(min-width: 1200px) and (max-width: 2499px)" srcSet={getImage("xl")} />
        <source media="(min-width: 769px) and (max-width: 1199px)" srcSet={getImage("md")} />
        <source media="(min-width: 376px) and (max-width: 768px)" srcSet={getImage("sm")} />
        <source media="(max-width: 375px)" srcSet={getImage("xs")} />
        <img
          src={getImage("xs")}
          alt={`${path} - Marketing Banner`}
        />
      </picture>
    </div>
  );
};

Banner.propTypes = {
  path: PropTypes.string.isRequired,
};

export default Banner;
