import React from "react";
import { UncontrolledTooltip, Col } from "reactstrap";
import PropTypes from "prop-types";
import downloadDoc from "assets/images/download-doc.png";
import notarizedIcon from "assets/images/notarized-badge.svg";
import { getBeUrl } from "helpers/utilHelper";
import { useAuth } from "context/auth";

const DocumentExpectedSection = ({ order, inkSignDocs, isDeliveryOptionUploaded, downloadBtnClassName, canBeDownload }) => {
  const { user } = useAuth();


  return <><h6>Document Expected </h6>
    {isDeliveryOptionUploaded &&
      <ul>
        <li>The dealership requested the below documents to be printed in place of being shipped. Click on the blue document icon to download and print.</li>
      </ul>}
    {/* Ink sign Documents */}
    {inkSignDocs?.filter(inkDoc => inkDoc.creatorId !== user.id && !inkDoc.isStdDoc).map((inkDoc, index) => <div key={index}>
      <a href={!inkDoc.isWithdrawn && canBeDownload() ? getBeUrl(`/order-doc/${inkDoc.id}/pdf/download`) : '#'}
        className={!inkDoc.isWithdrawn && canBeDownload() ? downloadBtnClassName : 'btn document-download pb-0'}
        id={`download-doc-${index}`}
      >
        {inkDoc.isWithdrawn && <UncontrolledTooltip placement="top" target={`download-doc-${index}`}>Document no longer available.</UncontrolledTooltip>}
        <div className="d-flex align-items-center">
          <Col sm='2'><img src={downloadDoc} alt="download-document" /></Col>
          <Col sm='9'>
            <div className='d-flex align-items-center'>
              <span className="text-start fw-medium font-size-14">{inkDoc.name}</span>
              {!!inkDoc.isNotarizationRequired && <span className="notarized-badge ms-3">
                to be notarized <img src={notarizedIcon} className='ms-2' alt='notarized-icon' />
              </span>}
            </div>
            {!!inkDoc.signingInstructions && <div className='document-download-info'>
              <h6 className='instructions-title'>Signing instructions</h6>
              <div className='instructions-description'>{inkDoc.signingInstructions}</div>
              <div className="divider-faded divider-sm my-2" />
            </div>}
          </Col>
          {isDeliveryOptionUploaded && <Col sm='1'>
            <i className="bx bx-download font-size-20" />
          </Col>}
        </div>
      </a>
    </div>)}

    {/* Return Label Document */}
    {!!order.returnShippingLabel &&
      <div><a href={getBeUrl(`order/${order.id}/return-label/download`)} className="btn document-download pb-0">
        <div className="d-flex justify-content-between align-items-center">
          <Col sm='2'><img src={downloadDoc} alt="download-document" /></Col>
          <Col sm='9'>
            <div className='d-flex align-items-center'>
              <span className="text-start fw-medium font-size-14">Return Label Information</span>
            </div>
          </Col>
          <Col sm='1'>
            <i className="bx bx-download font-size-20" />
          </Col>
        </div>
        <div className="divider-faded divider-sm my-2" />
      </a>
      </div>}
  </>
}

DocumentExpectedSection.propTypes = {
  order: PropTypes.object,
  inkSignDocs: PropTypes.array,
  isDeliveryOptionUploaded: PropTypes.bool,
  downloadBtnClassName: PropTypes.string,
  canBeDownload: PropTypes.func,
}

export default DocumentExpectedSection;