import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import * as Yup from "yup";
import { useFormik } from "formik";
import { Row, Col, Button, Form, Label, Input, FormFeedback, FormText, Alert } from "reactstrap";
import { nullsToEmptyStrings } from "helpers/utilHelper";
import Order from "model/order";
import Select from "react-select";
import DatePicker from "components/Shared/DatePicker";
import { generateLabelGetRating, generateLabelGetServiceType } from "helpers/backendHelper";
import { checkGenerateLabelError } from "helpers/labelGenerationErrorHelper";
import Preloader from "components/Shared/Preloader";
import { dateFromTimestamp, formatTimestamp, formats, timestamp } from "helpers/dateHelper";
import AdditionalFee from "model/additionalFee";
import moment from "moment-timezone";

const ShippingMethod = props => {

  const { defaultValues, tabId, nextHandler, prevHandler, order, serviceTypes, setServiceTypes, allValues, deliveryRating, setDeliveryRating, availableFees } = props;

  /********** STATE **********/

  // estimation fee received by Fedex
  const [estimatedPrice, setEstimatedPrice] = useState();
  // arrival expected date
  const [arrivalExpectedDate, setArrivalExpectedDate] = useState("");
  // processing fee from Mav
  const [feeProcessingPrice, setFeeProcessingPrice] = useState(0);
  // whether the service type is fetched
  const [isGetServiceTypeInProgress, setIsGetServiceTypeInProgress] = useState(false);
  // whether the delivery rating is fetched
  const [isDeliveryRatingInProgress, setIsDeliveryRatingInProgress] = useState(false);
  // whether the rating or the service throws error
  const [serviceTypeError, setServiceTypeError] = useState();
  // we use this as a way to force react to unmount and recreate components
  // this seems to be the only way to clear the select control
  const [nonce, setNonce] = useState(0);

  // schema validation
  const validationSchema = {
    deliveryMethod: Yup.object().shape({
      serviceName: Yup.string().trim().required("Field is required"),
      serviceType: Yup.string().trim().required("Field is required"),
    }),
    packagingType: Yup.object().shape({
      packageName: Yup.string().trim().required("Field is required"),
      packageType: Yup.string().trim().required("Field is required"),
    }),
    shipDate: Yup.string().when([], {
      is: () => order.docDeliveryOption === Order.DOC_DELIVERY_OPTION_SHIPPING,
      then: Yup.string().trim().required("Field is required").nullable(),
    })
  }

  /********** FORM CONFIG **********/

  const formInitialValues = {
    // Dealer Details
    deliveryMethod: {
      serviceType: '',
      serviceName: ''
    },
    packagingType: {
      packageType: Object.keys(Order.getFedexPackagingTypes())[0],
      packageName: Object.values(Order.getFedexPackagingTypes())[0]
    },
    shipDate: order.docDeliveryOption === Order.DOC_DELIVERY_OPTION_SHIPPING ? order.docsSentToNotaryTs : undefined,
    ...nullsToEmptyStrings(defaultValues),
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object(validationSchema),
    onSubmit: values => {
      formik.setSubmitting(false);
      nextHandler(tabId, values);
    },
  });

  /********** EFFECTS **********/

  // gets the service type when load or when shpiDate changes - this retrieves the available delivery methods
  useEffect(() => {
    // Get the service type
    getServiceType();
  }, [formik.values.shipDate])

  // after selecting the delivery method we need to get the label rating based on the selection
  useEffect(() => {
    if (!!formik.values.deliveryMethod.serviceType.length) {
      // Clear errors if any
      setServiceTypeError(undefined)
      getLabelRating(formik.values.deliveryMethod.serviceType)
    }
  }, [formik.values.deliveryMethod.serviceType])

  // after we have the delivery rating we can calculate the estimate price
  useEffect(() => {
    if (!!availableFees?.length && !!deliveryRating) {
      getEstimatedPrice(deliveryRating[0]);
      setFeeProcessingPrice(availableFees.find((fee) => fee.id === AdditionalFee.ID_GENERATE_LABEL_PROCESSING).price);
    }
  }, [availableFees, deliveryRating])

  // checks the ship date, if the shipdate is in the past, set the current day otherwise set the shipdate
  useEffect(() => {
    // only for shipping options
    if (order.docDeliveryOption === Order.DOC_DELIVERY_OPTION_SHIPPING) {
      const today = moment();
      const shipDate = moment(dateFromTimestamp(formik.values.shipDate));

      if (shipDate.date() < today.date() && shipDate.month() <= today.month()) {
        formik.setFieldValue("shipDate", timestamp())
      } else {
        formik.setFieldValue("shipDate", order.docsSentToNotaryTs)
      }
    }
  }, [])

  /********** EVENT HANDLERS **********/

  const getServiceType = () => {
    setIsGetServiceTypeInProgress(true);
    generateLabelGetServiceType(order.id, { ...allValues[0], shipDate: !!formik.values.shipDate ? formatTimestamp(formik.values.shipDate, formats.SHIP_DATE_FORMAT) : undefined })
      .then(response => {
        setServiceTypes(response.services)
        // reset upload form fields
        // this is needed because the select control cannot be reset easily
        setNonce(n => n + 1);
        // Clear the values because the new rating might get different deliveries
        formik.setFieldValue('deliveryMethod', {
          serviceType: '',
          serviceName: ''
        })
        // Clear the delivery rate and estimated price
        setDeliveryRating(null);
        setEstimatedPrice(undefined);
      })
      .catch(ex => {
        checkGenerateLabelError(ex)
        setServiceTypeError(ex);
      })
      .finally(() => {
        setIsGetServiceTypeInProgress(false);
      })
  }

  const getLabelRating = (serviceType) => {
    setIsDeliveryRatingInProgress(true);
    generateLabelGetRating(order.id, { ...allValues[0], shipDate: !!formik.values.shipDate ? formatTimestamp(formik.values.shipDate, formats.SHIP_DATE_FORMAT) : undefined, serviceType: serviceType })
      .then(response => {
        setDeliveryRating(response.rate)
        setEstimatedPrice(undefined)
      })
      .catch(ex => {
        checkGenerateLabelError(ex)
        setServiceTypeError(ex);
      })
      .finally(() => {
        setIsDeliveryRatingInProgress(false);
      })
  }

  // handler that returns the deliver method options based on the rating
  // each delivery company has a different approach so we need to go
  // through both cases
  const getDeliveryMethodOptions = () => {
    if (order.shippingCompany === Order.SHIPPING_COMPANY_FEDEX) {
      const deliveryMethods = serviceTypes;
      const deliveryOptions = deliveryMethods.map((method) => {
        return {
          label: method.serviceType.displayText,
          value: {
            serviceName: method.serviceType.displayText,
            serviceType: method.serviceType.key
          }
        }
      })

      return deliveryOptions;
    }
  }

  const getPackagingTypeOptions = () => {
    if (order.shippingCompany === Order.SHIPPING_COMPANY_FEDEX) {
      const packagingTypes = Order.getFedexPackagingTypes();
      const packagingTypeOptions = Object.keys(packagingTypes).map((type) => {
        return {
          label: packagingTypes[type],
          value: {
            packageType: type,
            packageName: packagingTypes[type]
          }
        }
      })

      return packagingTypeOptions;
    }
  }

  // Extracting price from provider response
  const getEstimatedPrice = (serviceRate) => {
    if (!!serviceRate) {
      const accountRate = serviceRate.ratedShipmentDetails.find((rate) => rate.rateType === "MAV");
      if (order.docDeliveryOption === Order.DOC_DELIVERY_OPTION_SHIPPING) {
        setEstimatedPrice(accountRate.outgoingFee + accountRate.returnFee); // Adding both for shipping (return and outgoing labels)
      } else {
        setEstimatedPrice(accountRate.returnFee);
      }
      // Setting the delivery date
      const deliveryDate = timestamp(new Date(serviceRate.operationalDetail.deliveryDate));
      const formattedDate = formatTimestamp(deliveryDate, formats.TRACKING_DATE);
      setArrivalExpectedDate(formattedDate)
    }
  }

  // focus event handler
  // used to clear field errors
  const onFieldFocused = (e, fieldName) => {
    const name = fieldName || e.target.name;
    const errors = formik.errors;
    delete errors[name];
    formik.setStatus(errors);
  }

  return <React.Fragment>
    <Row className="shipping-method-container">
      <Col>
        <Form>
          <div className="card-title pt-1 pb-3 mb-0">Shipping Information</div>
          {
            !!serviceTypeError &&
            <Alert color="danger ms-0">
              {
                order.docDeliveryOption === Order.DOC_DELIVERY_OPTION_SHIPPING
                  ?
                  'An error occurred while retrieving the shipping rate. Please modify the date or addresses in the previous step and try again.'
                  :
                  'An error occurred while retrieving the shipping rate. Please modify the addresses in the previous step and try again.'
              }
            </Alert>
          }
          {
            order.docDeliveryOption === Order.DOC_DELIVERY_OPTION_SHIPPING &&
            <Row className="mb-4">
              <Col xs={12} md={6}>
                <Label className="col-form-label">Ship Date *</Label>
                <DatePicker
                  name="shipDate"
                  value={formik.values.shipDate}
                  onChange={selected => formik.setFieldValue("shipDate", selected)}
                  onFocus={onFieldFocused}
                  minDate={new Date().setHours(0, 0, 0, 0)}
                  invalid={!!formik.errors.shipDate}
                  disabled={isGetServiceTypeInProgress}
                />
                {!!formik.errors.shipDate && <FormFeedback type="invalid" className="d-block">{formik.errors.shipDate}</FormFeedback>}
              </Col>
            </Row>
          }
          <div className="position-relative">
            {
              !!serviceTypes?.length &&
              <>
                <Row className="mb-4">
                  <Col sm={6}>
                    <Label className="col-sm-4 col-form-label">Packaging Type *</Label>
                    <Select
                      classNamePrefix="select2-selection"
                      name="packagingType"
                      isDisabled
                      options={getPackagingTypeOptions()}
                      onChange={selected => formik.setFieldValue('packagingType', selected.value)}
                      onFocus={e => onFieldFocused(e, 'packagingType')}
                      value={getPackagingTypeOptions().find(option => option.value.packageType === formik.values.packagingType.packageType)}
                      className={!!formik.errors.packagingType && 'is-invalid'} />
                    {!!formik.errors.packagingType && <FormFeedback type="invalid">{formik.errors.packagingType.packageType}</FormFeedback>}
                  </Col>
                  <Col sm={6}>
                    <Label className="col-sm-4 col-form-label">Choose A Delivery Method *</Label>
                    <Select
                      classNamePrefix="select2-selection"
                      name="deliveryMethod"
                      key={"SelectType" + nonce}
                      isDisabled={isDeliveryRatingInProgress}
                      options={getDeliveryMethodOptions()}
                      onChange={selected => formik.setFieldValue('deliveryMethod', selected.value)}
                      onFocus={e => onFieldFocused(e, 'deliveryMethod')}
                      value={getDeliveryMethodOptions().find(option => option.value.serviceType === formik.values.deliveryMethod.serviceType)}
                      className={!!formik.errors.deliveryMethod && 'is-invalid'} />
                    {!!formik.errors.deliveryMethod && <FormFeedback type="invalid">{formik.errors.deliveryMethod.serviceType}</FormFeedback>}
                  </Col>
                </Row>
                {
                  !!availableFees && !!formik.values.deliveryMethod.serviceType.length &&
                  <>
                    {
                      isDeliveryRatingInProgress || !estimatedPrice
                        ?
                        <div className="skeleton"></div>
                        :
                        <div>
                          <div className="pb-3 d-flex flex-direction-row align-items-center">
                            <div className="font-weight-600">Arrival Expected Date -</div>
                            <div className="font-weight-600 ms-1">
                              {arrivalExpectedDate}
                            </div>
                          </div>
                          <div className="pb-3 d-flex flex-direction-row align-items-center">
                            <div>Estimated Delivery Price</div>
                            <div className="ms-2">${estimatedPrice.toFixed(2)}</div>
                          </div>
                          <div className="pb-3 d-flex flex-direction-row align-items-center">
                            <div>Processing Fee</div>
                            <div className="ms-2">${feeProcessingPrice}</div>
                          </div>
                          <div className="pb-3 d-flex flex-direction-row align-items-center">
                            <div className="font-weight-600">Estimated Total Price</div>
                            <div className="font-weight-600 ms-2">
                              ${(+estimatedPrice + +feeProcessingPrice).toFixed(2)}
                            </div>
                          </div>
                          <Row className="mb-2">
                            <Col>
                              <div className="mt-1 font-size-11"><i className="mdi mdi-information-outline me-1 font-size-12"></i>Please note that the price displayed is an approximation and may vary once the label is generated.</div>
                            </Col>
                          </Row>
                        </div>
                    }
                  </>
                }
              </>
            }
            {/* Show this prealoder when the user changes dates */}
            {isGetServiceTypeInProgress && <Preloader className="shipping-method-container-loader" />}
          </div>
        </Form>
      </Col>
    </Row>
    <div className="pb-4">
      <Row>
        <Col>
          <div className="text-end">
            <Button type="button" color="secondary" className="ms-2 mb-2" onClick={() => prevHandler(tabId, formik.values)}>
              <i className="mdi mdi-chevron-left me-1" />
              Previous
            </Button>
            <Button type="button" color="primary" className="ms-2 mb-2" onClick={formik.handleSubmit} disabled={formik.isSubmitting || !!serviceTypeError || !serviceTypes || isGetServiceTypeInProgress || isDeliveryRatingInProgress}>
              <i className="mdi mdi-check me-1" />
              Next
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  </React.Fragment >
}

ShippingMethod.propTypes = {
  defaultValues: PropTypes.object,
  availableFees: PropTypes.any,
  tabId: PropTypes.number,
  nextHandler: PropTypes.func,
  prevHandler: PropTypes.func,
  order: PropTypes.object,
  setServiceTypes: PropTypes.func,
  serviceTypes: PropTypes.array,
  deliveryRating: PropTypes.array,
  setDeliveryRating: PropTypes.func,
  allValues: PropTypes.object
};

export default ShippingMethod;